import React, { useState, useRef, useEffect} from 'react';
import { Container, Row, Col, Button, Form, Modal, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from 'react-hot-toast';
import Select, { components } from "react-select";
import { linkedin } from 'react-linkedin-login-oauth2/assets/linkedin.png';
import CreatableSelect from 'react-select/creatable';
// import { Country, State } from "country-state-city";



const RegistrantAdd = ({ show, setShow, token, nrdID, jurisdictions, Notes}) => {
    const navigate = useNavigate()
    const [linkedin, setLinkedin] = useState('');
    const [isReadonly, setIsReadonly] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [experiences, setExperiences] = useState([]);
    const [volunteerExperince, setVolunteerExperince] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [firmCategoryOptions, setFirmCategoryOptions] = useState([]);
    const [formDisabled, setFormDisabled] = useState(true);
    const [regions, setRegions] = useState([]);
    const [education, setEducation] = useState([]);
    // const [selectedRegion, setSelectedRegion] = useState(null);
    const [newLanguage, setNewLanguage] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [newSkill, setNewSkill] = useState('');
    const selectInputRef = useRef();
    const [isDisableds1, setIsDisableds1] = useState(true);
    const [selectedOption, setSelectedOption] = useState('profile_image');
    const [updatedCountries, setUpdatedCountries] = useState();
    const [updatedStates, setUpdatedStates] = useState();
    const [cityData, setCityData] = useState();
    const [profileImage, setProfileImage] = useState("");
    const [isdisabled, setIsDisabled] = useState(true);
    const [iscitydisabled, setIsCityDisabled] = useState(true);
    let countryName = "";
    let stateName = "";
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    const [formData, setFormData] = useState({
        registrant_name: '',
        current_job_title: '',
        number_connections: '',
        number_followers: '',
        work_email: '',
        personal_email: '',
        phone_number: '',
        languages: [],
        skills: [],
        description: '',
        registarntResourceUrl: '',
        current_company_name: '',
        headline: '',
        company_url: '',
        company_industry: '',
        city: '',
        location: '',
        postalCode: '',
        country: '',
        province: '',
    });

    const [address, setAddress] = useState({
        country: null,
        state: null,
    });
    
    const [newRegions, setNewRegions] = useState({
        region: '',
        titles: [],
        term_and_condition: '',
    });
      
    const [newEducation, setNewEducation]=useState({
       school_name:'',
       linkedin_school_url:'',
    });

    const [newExperince, setNewExperince]=useState({
       title:'',
       company_name:'',
       company_description: '',
       location: '',
        date:'',
    });

    const filteredJurisdictions = jurisdictions?.slice(1);
    const options = filteredJurisdictions?.map(jurisdiction => {
        return { value: jurisdiction, label: jurisdiction };
    });

    // errors

    const [errors, setErrors] = useState({
        registrant_name: '',
        current_job_title: '',
        profile_country: '',
        description: '',
        current_company_name: '',
        role: '',
        headline: '',
        company_url: '',
        company_industry: '',
        registarntResourceUrl: '',
        titles: '',
        region: '',
        country: '',
        provience: '',
        city: '',
        location: '',
        postalCode: ''
    });

    const [linkedinError, setLinkedinError] = useState({ linkedin_link: '' });

    const [regionErrors, setRegionError] = useState({
        region: '',
        titles: '',
    });

    const [educationErrors, setEducationErrors] = useState({
        school_name: '',
        linkedin_school_url: '',
    });

    const [experienceErrors, setExperienceErrors] = useState({
        title: '',
        company_name: '',
        company_description: ''
    });
    
    let newErrors = { ...errors };
    let newRegionErrors = { ...regionErrors };
    let newEducationErrors = { ...educationErrors };
    let newExperienceErrors = { ...experienceErrors };

    const DropdownIndicator = (props) => {
        return components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ? <i class="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
          </components.DropdownIndicator>
        );
    };



    // onchange functions
    
    const handleLinkinURLChange = (e) => {
        const link = e.target.value;
        setLinkedin(link);
        validateLinkedinField('linkedin_link', link);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const filteredValue = value?.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setFormData({
            ...formData,
            [name]: trimmedValue,
        });
        validateField(name, value);
    };

    const handleEducation = (e) => {
        const { name, value } = e.target;
        const filteredValue = value?.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setNewEducation({
            ...newEducation,
            [name]: trimmedValue,
        });
        validateEducationField(name, value);
    };

    const handleExperince = (e) => {
        const { name, value } = e.target;
        const filteredValue = value?.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setNewExperince({
            ...newExperince,
            [name]: trimmedValue,
        });
        validateExperienceField(name, value);
    };

     const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

    const handleCountryChange = (value) => {
        stateApi(value)
        countryName = value.label;
        formData.country = countryName
        countryValidateField('country', formData.country);
        setFormData({
            ...formData,
            country: countryName,
        });
        setIsDisabled(false);
        setIsCityDisabled(true);
        stateValidateField('province', stateName);
        handleAddressChange({ country: value, state: null });
    };

    const handleStateChange = (label) => {
        CityApi(label);
        setIsDisableds1(false);
        setFormData({
            ...formData,
            province: label.label,
        });
        stateName = label.label;
        setIsCityDisabled(false);
        stateValidateField('province', stateName);
        handleAddressChange({ ...address, state: label });
    };

    const handleCity = (e) => {
        setFormData({
            ...formData,
            city: e.label,
        });
    };

    // Regions section

    const handleRegions = (e) => {
        const { name, value } = e.target;
        const filteredValue = value?.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setNewRegions({
            ...newRegions,
            [name]: trimmedValue,
        });
        validateRegionField(name, value);
    };

    const handleRegionChange = (selectedRegion) => {
        RegionValidateField('region', newRegions.region);
        setNewRegions({
            ...newRegions,
            region: selectedRegion?.label,
        });
    };

    const handleCategoryChange = (newValue) => {
        const options = Array?.isArray(newValue) ? newValue : [];
        const filteredOptions = options?.filter(option => {
            const trimmedLabel = option?.label?.trimEnd();
            return trimmedLabel === option?.label;
        });
        const titles = filteredOptions?.map(option => option?.label);
    
        setSelectedOptions(filteredOptions);
        // ValidateCategory('categories', categories);
        setNewRegions({
            ...newRegions,
            titles: titles,
        });
    };

    const addNewRegions = () => {
        const isRegionSelected = regions.some(region => region.region === newRegions.region);
        if (newRegions?.length === 0) {
            toast.error("Region selection is required");
            return;
        } else if (newRegions?.region?.trim() === '') {
            toast.error("Region is required");
            return;
        } else if (newRegions?.titles?.length === 0) {
            toast.error("Titles are required");
            return;
        } else if (isRegionSelected) {
            toast.error("Region already exists");
            selectInputRef.current.clearValue();
            setNewRegions({
                region: '',
                titles: [],
                term_and_condition: '',
            });

            return;
        }
        else {
            setRegions([...regions, newRegions]);
            selectInputRef.current.clearValue();
            setNewTitle('');
            setNewRegions({
                region: '',
                titles: [],
                term_and_condition: '',
            });
            setSelectedOptions([]);

        }
    };

    const handleDeleteRegion = (index) => {
        const updatedRegion = [...regions];
        const deletedRegion = updatedRegion?.splice(index, 1)[0];
        setRegions(updatedRegion)
    };

    const handleupload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file type
            if (file.type === "image/jpeg" || file.type === "image/png") {
                // Check file size (2MB limit)
                if (file.size <= 2 * 1024 * 1024) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result;

                        setFile(base64String);
                        setProfileImage(base64String);
                    };
                    reader.readAsDataURL(file);
                } else {
                    toast.error("File size should not exceed 2MB.");
                }
            } else {
                toast.error("Please select a valid JPG or PNG file.");
            }
        };
    };


    const handleLanguages = () => {
        if (formData?.languages?.includes(newLanguage)) {
            setNewLanguage('');
        } else if (newLanguage) {
            setFormData((prevData) => ({
                ...prevData,
                languages: [...prevData?.languages || [], newLanguage],
            }));
            setNewLanguage('');
        } else {
            setNewLanguage('');
        }
    };

    const handleDeleteLanguages = (index) => {
        let deletedLanguages = formData.languages?.filter((language, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            languages: deletedLanguages,
        }));
    };

    const handleLanguageChange = (e) => {
        setNewLanguage(e.target.value)
    };

    const handleSkillChange = (e) => {
        setNewSkill(e.target.value);
    };

    const handleSkills = () => {
        if (formData?.skills?.includes(newSkill)) {
            setNewSkill('');
        } else if (newSkill) {
            setFormData((prevData) => ({
                ...prevData,
                skills: [...prevData?.skills || [], newSkill],
            }));
            setNewSkill('');
        } else {
            setNewSkill('');
        }
    };

    const handleDeleteSkills = (index) => {
        let deletedSkills = formData?.skills?.filter((skills, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            skills: deletedSkills,
        }));
    };

    const handleClose = () => {
        setShow(false);
        setFormData({});
        setErrors({});
        setLinkedin('');
        setFile(null);
        setIsReadonly(false);
        setNewExperince({});
        // setNewVolunteerExperince({});
        setNewEducation({});
        setNewRegions({});
        setLinkedinError({});
        setEducationErrors({});
        setExperienceErrors({});
        setExperiences([]);
        setVolunteerExperince([]);
        setRegions([]);
        setEducation([]);
        setFile(null);
        setPreviewUrl('');
        setSelectedOptions([]);
        setRegions([]);
        setEducation([]);
        setVolunteerExperince([]);
        // setSelectedRegion(null);
        setFormDisabled(true);
    };
    const handleReset = () => {
        setLinkedin('');
        // setFormData({});
        setFile(null);
        setRegions([]);
        setFormDisabled(true);
        setIsReadonly(false);
        setErrors({});
        setSelectedOptions([]);
        setPreviewUrl('');
        setLinkedinError({});
        setFormData({
            linkedin_url: '',
            registrant_name: '',
            current_job_title: '',
            number_connections: '',
            number_followers: '',
            work_email: '',
            personal_email: '',
            phone_number: '',
            languages: [],
            skills: [],
            description: '',
            registarntResourceUrl: '',
            current_company_name: '',
            headline: '',
            company_url: '',
            company_industry: '',
            country: '',
            province: '',
            city: '',

        });

        setAddress({
            country: null,
            state: null,
        })

    };
    
    const handleCancelImage = async (e) => {
        setFile(null);
    };


    const handleDeleteTitles = (index) => {
        let deletedTitles = newRegions?.titles?.filter((titles, i) => i !== index);
        setNewRegions((prevData) => ({
            ...prevData,
            titles: deletedTitles,
        }));
    };



    const addNewEducation = () => {
        educationvalidateForm();
        if (!Object.values(newEducationErrors).some((error) => error !== '')) {
            setEducation([...education, newEducation]);

            setNewEducation({
                school_name: '',
                linkedin_school_url: '',
            });
            setEducationErrors({});
        }
    };

    const handleEducationDelete = (index) => {
        const updatedEducation = [...education];
        const deletedEducation = updatedEducation?.splice(index, 1)[0];
        setEducation(updatedEducation)
    };

    const addNewExperince = () => {
        experiencevalidateForm();
        if (!Object.values(newExperienceErrors).some((error) => error !== '')) {
            setExperiences([...experiences, newExperince]);
            setNewExperince({
                title: '',
                company_name: '',
                company_description: '',
                location: '',
                date: ''
            });
            setExperienceErrors({});
        }

    };

    const handleDeleteExperinces = (index) => {
        const updatedExperince = [...experiences];
        const deletedExperiences = updatedExperince?.splice(index, 1)[0];
        setExperiences(updatedExperince)
    };


    //validations

    const validateLinkedinField = (fieldName, value) => {
        let error = '';
        if (fieldName === 'linkedin_link') {
            // const pattern =/https:\/\/[a-z]{2,3}\.linkedin\.com\/in\/[a-zA-Z0-9-–%®]+/;
            const pattern = /^(https?:\/\/)?(www\.)?([a-z]+\.)*linkedin\.com\/[a-zA-Z0-9-–%®]+(?:\/[^\/]+)?\/?$/;

            if (value.trim() === '') {
                error = 'LinkedIn URL is required.';
            } else if (!pattern.test(value)) {
                error = 'Please enter a valid URL! Example: https://www.linkedin.com/in/name/';
            }
        }
        setLinkedinError(prevState => ({
            ...prevState,
            [fieldName]: error
        }));
    };

     const validateField = (fieldName, value) => {
        
        if (fieldName === 'registrant_name') {
            newErrors.registrant_name = value === undefined || value === "" ? 'Registrant name is required.' : '';
        }else if (fieldName === 'current_job_title') {
            newErrors.current_job_title = value === undefined  || value === "" ? 'Current Job Title  is required.' : '';
        } else if (fieldName === 'phone_number') {
            let pattern = /^\d{5,10}$/;
             if (!value) {
                newErrors.phone_number = '';
            } else if (!pattern.test(value)) {
                newErrors.phone_number = 'Invalid Format';
            }
            else {
                newErrors.phone_number = ''
            }
        } else if (fieldName === 'work_email') {
             const emailRegex = /^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!value) {
                newErrors.work_email = '';
            } else if (!emailRegex.test(value)) {
                newErrors.work_email = 'Invalid Format';
            }
            else {
                newErrors.work_email = ''
            }
        } else if (fieldName === 'personal_email') {
            const emailRegex = /^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!value) {
                newErrors.personal_email = '';
            } else if (!emailRegex.test(value)) {
                newErrors.personal_email = 'Invalid Format';
            }
            else {
                newErrors.personal_email = ''
            }
        } 
        else if (fieldName === 'registarntResourceUrl') {
            const pattern = /^https:\/\/info\.securities-administrators\.ca\/nrsmobile\/NRSIndivRegistrationRecord\.aspx\?indivId=\d+$/;
            if (!value) {
                newErrors.registarntResourceUrl = '';
            } else if (!pattern.test(value)) {
                newErrors.registarntResourceUrl = 'Please enter a valid link! Example: https://info.securities-administrators.ca/nrsmobile/NRSIndivRegistrationRecord.aspx?indivId=id';
            }
            else {
                newErrors.registarntResourceUrl = ''
            }
        } 
        else if (fieldName === 'postalCode') {
            let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
            if(value?.trim()?.length  === 0){
                newErrors.postalCode = ''
            }
            else if(value?.length !==0){
                    if (!pattern.test(value)) {
              newErrors.postalCode = 'Invalid  postal code';
            }else {
              newErrors.postalCode = ''
            }
            }
      
      
          }
        else if (fieldName === 'current_company_name') {
            newErrors.current_company_name = value === undefined || value === "" ?'Current company name  is required.' : '';
        } else if (fieldName === 'company_url') {
            let pattern = /^(https?:\/\/)((www|\w\w)\.)?linkedin\.com\/(([\w]{2,3})?|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
            if (value === undefined) {
                newErrors.company_url = ' Company Linkedin URL is required.';
            } else if (!pattern.test(value)) {
                newErrors.company_url = 'Please enter a valid URL! Example: http://linkedin.com/in/name/';
            } else {
                newErrors.company_url = '';
            }
        } else if (fieldName === 'company_industry') {
            newErrors.company_industry = value === undefined  || value === "" ? 'Current industry is required.' : '';
        } 
        setErrors(newErrors);
    };

    const countryValidateField = (country, value) => {
        const newErrors = { ...errors };
        if (value === '' || value === undefined || !value) {
            newErrors.country = 'Country is required.';
        } else if (value?.length > 1) {
            delete newErrors.country;
        }
        setErrors(newErrors);
        RegionCountryValidateField('country', address?.country?.name);
    };

    const RegionCountryValidateField = (country, value) => {
    if (country === 'region_country') {

      newErrors.regionCountry = value === '' ? 'Country field is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);

    };

    const stateValidateField = (fieldName, value) => {
        if (fieldName === 'province') {
            newErrors.province = value === '' ? 'Province field is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);
    };
    const RegionStateValidateField = (fieldName, value) => {
        if (fieldName === 'region_name') {
            newErrors.reginState = value === '' ? 'Province field is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);
    };

    const RegionValidateField = (region, value) => {
        const isRegionSelected = regions.some(r => r.region === value);
        if (region === 'region') {
            if (value === '') {
                newRegionErrors.region = 'Region is required.';
            } else if (isRegionSelected) {
                newRegionErrors.region = 'Region already exists.';
            } else {
                newRegionErrors.region = '';
            }
        }

    };

    const validateRegionField = (fieldName, value) => {
        const isRegionSelected = regions?.some(region => region?.region === newRegions?.region);
        switch (fieldName) {
            case 'region':
                if (!value) {
                    newRegionErrors.region = 'Region is required2.';
                } else if (value && isRegionSelected(value)) {
                    newRegionErrors.region = 'Region is already present2.';
                } else {
                    newRegionErrors.region = '';
                }
                break;

            case 'titles':
                if (value === '') {
                    newRegionErrors.titles = 'Title is required.';
                } else {
                    newRegionErrors.titles = '';
                }
                break;

            default:
                break;
        }

        setRegionError(newRegionErrors);
    };

    const validateEducationField = (name, value) => {
        if (name === 'school_name') {
            newEducationErrors.school_name = value === '' ? 'School name is required.' : '';
        } else if (name === 'linkedin_school_url') {
            let pattern = /^(https?:\/\/)((www|\w\w)\.)?linkedin\.com\/(([\w]{2,3})?|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
            if (value && !pattern.test(value)) {
                newEducationErrors.linkedin_school_url = 'Please enter a valid URL! Example: http://linkedin.com/in/name/';
            } else {
                newEducationErrors.linkedin_school_url = '';
            }
        
        }
        setEducationErrors(newEducationErrors);
    };

    const validateExperienceField = (fieldName, value) => {
        if (fieldName === 'title') {
            if (value === '') {
                newExperienceErrors.title = 'Experience title is required.';
            } else if (value.length > 100) {
                newExperienceErrors.title = 'Title should not exceed 100 characters.';
            } else {
                newExperienceErrors.title = '';
            }
        } else if (fieldName === 'company_name') {
            if (value === '') {
                newExperienceErrors.company_name = 'Company Name is required.';
            } else if (value.length > 150) {
                newExperienceErrors.company_name = 'Company Name should not exceed 150 characters.';
            } else {
                newExperienceErrors.company_name = '';
            }
        } else if (fieldName === "company_description") {
            if (value && value?.length > 150) {
                newExperienceErrors.company_description = 'Company Description should not exceed 150 characters.';
            } else {
                newExperienceErrors.company_description = '';
            }
        }
        setExperienceErrors(newExperienceErrors);
    };

     const validateForm = () => {
        for (const fieldName in formData) {
            if (formData.hasOwnProperty(fieldName)) {
                validateField(fieldName, formData[fieldName]);
                
      }
    }
    setErrors(newErrors);
    setIsDisable(false);
    };

    const ValidateCategory = (fieldName, value) => {
        if (fieldName === 'categories') {
            newErrors.reginCatagores = value?.length === 0 ? 'At least one category must be selected.' : '';
            setErrors(newErrors);
        }

        setErrors(newErrors);

    };

    const educationvalidateForm = () => {
        for (const name in newEducation) {
            if (newEducation.hasOwnProperty(name)) {
                validateEducationField(name, newEducation[name])
            }
        }
        // setErrors(newEducationErrors);
        setEducationErrors(newEducationErrors);
    };

    const experiencevalidateForm = () => {
        for (const name in newExperince) {
            if (newExperince.hasOwnProperty(name)) {
                validateExperienceField(name, newExperince[name])
            }
        }
        // setErrors(newExperienceErrors);
        setExperienceErrors(newExperienceErrors);
    };


    useEffect(() => {
        if (show === true) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                const options = res?.data?.map(province => ({
                    value: province._id,
                    label: province.name
                }));
                setUpdatedCountries(options);
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });
        }
    }, [show]);

    const stateApi = (value) => {
        // if (logindata?.token) {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Id': value.value,
            },
        }).then((res) => {
            const options1 = res?.data?.map(name => ({
                value: name._id,
                label: name.name
            }));
            setUpdatedStates(options1);
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
        // }
    };

    const CityApi = (value) => {
        // if (logindata?.token) {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Id': value.value,
            },
        }).then((res) => {
            const options1 = res?.data?.map(name => ({
                value: name._id,
                label: name.name
            }));
            setCityData(options1);
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
        // }
    };

    useEffect(() => {
        if (formDisabled === false) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant/category-filter`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    const categoryOptions = response?.data?.data?.map(category => ({
                        value: category?._id,
                        label: category?.category
                    }));
                    setFirmCategoryOptions(categoryOptions);
                }).catch((error) => {
                    // if (error?.response?.status == 404) {
                    //     navigate('/admin/not-found')
                    // } else
                     if (error?.response?.data?.messsage === "Unauthozied User") {
                        toast.error(error?.response?.data?.messsage);
                    }
                    console.error('Error fetching firm Categories:', error);
                });
        }
    }, [show, formDisabled]);

    const checkRegistrant = async (e) => {
        e.preventDefault();
        validateLinkedinField('linkedin_link', linkedin);
        if (linkedinError.linkedin_link === '') {
            setIsReadonly(true);
            setFormDisabled(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant/find`,
                    {
                        linkedin_link: linkedin,
                        NRD: nrdID,
                    }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const registrant_Info = response?.data?.registrant_Information;
                setFormData((prevData) => ({
                    ...prevData,
                    name: registrant_Info?.name,
                    current_job_title: registrant_Info?.current_job_title,
                    number_connections: registrant_Info?.number_connections,
                    number_followers: registrant_Info?.number_followers,
                    work_email: registrant_Info?.work_email,
                    personal_email: registrant_Info?.personal_email,
                    phone_number: registrant_Info?.phone_number,
                    languages: registrant_Info?.languages,
                    skills: registrant_Info?.skills,
                    description: registrant_Info?.description,
                    registarntResourceUrl: registrant_Info?.registrant_resource_url,
                    current_company_name: registrant_Info?.current_company_name,
                    headline: registrant_Info?.headline,
                    company_industry: registrant_Info?.company_industry,
                    company_url: registrant_Info?.company_url,
                    country: registrant_Info?.country ? { label: registrant_Info.country, value: registrant_Info.country } : '',
                    province: registrant_Info?.province ? { label: registrant_Info.province, value: registrant_Info.province } : '',
                    city: registrant_Info?.city,
                    location: registrant_Info?.location ? registrant_Info?.location : '',
                    postalCode: registrant_Info.postal_code ? registrant_Info.postal_code : ''
                }));
                setAddress((prevData) => ({
                    ...prevData,
                    country: registrant_Info?.country ? { label: registrant_Info.country, value: registrant_Info.country } : '',
                    state: registrant_Info?.province ? { label: registrant_Info.province, value: registrant_Info.province } : ''
                }));
                if (registrant_Info?.education === undefined || 'undefined') {
                    setEducation([]);
                } else {
                    setEducation(registrant_Info?.education)
                }

                if (registrant_Info?.experiences === undefined || 'undefined') {
                    setExperiences([]);
                } else {
                    setExperiences(registrant_Info?.experiences);
                }
                setVolunteerExperince(registrant_Info?.volunteer_experince);
                setFile(registrant_Info?.profile_image_url);
                toast.success(response?.data?.message)

                if (response?.data?.message === "Registrant with same Linked in restored.") {
                    setShow(false)
                    setIsReadonly(false)
                    Notes();
                    setLinkedin('');
                    setFormDisabled(true);
                } else if (response?.data?.message === "This is a new registration") {
                    setIsReadonly(true)
                    setFormDisabled(false);
                } else if (response?.data?.message === "Registrant match found") {
                    setIsReadonly(true)
                    setFormDisabled(false);
                }
            } catch (error) {
                if (error?.message === "Request failed with status code 401") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin/login");
                    toast.error(`Unauthozied user !`);
                } else if (error.response.data.message === "Registrant already exists in this firm") {
                    toast.error(error.response.data.message);
                    setShow(false);
                    setLinkedin('');
                    setFormDisabled(true);
                }

                setIsReadonly(false);
                setFormDisabled(true);
            }
        }
    };

    const handleRegistrantSubmit = async (e) => {
        e.preventDefault();
        setIsDisable(true);
        countryValidateField();
        validateForm();
        if (regions?.length === 0) {
            toast.error(`Region selection is required `);
        } else if (!Object.values(newErrors).some((error) => error !== '')) {
            setIsDisable(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/add-registrant/${nrdID}`,
                    {
                        linkedin_link: linkedin,
                        name: formData?.registrant_name,
                        current_job_title: formData?.current_job_title,
                        number_connections: Number(formData?.number_connections),
                        number_followers: Number(formData?.number_followers),
                        work_email: formData?.work_email,
                        personal_email: formData?.personal_email,
                        phone_number: formData?.phone_number,
                        languages: formData?.languages,
                        registrant_resource_url: formData?.registarntResourceUrl,
                        skills: formData?.skills,
                        description: formData?.description,
                        education: education,
                        experiences: experiences,
                        // volunteer_experince: volunteerExperince,
                        current_company_name: formData?.current_company_name,
                        headline: formData?.headline,
                        company_url: formData?.company_url,
                        company_industry: formData?.company_industry,
                        profile_image: profileImage,
                        regions: regions,
                        country: address.country.label,
                        province: address.state.label,
                        city: formData?.city,
                        postal_code: formData?.postalCode,
                        location: formData?.location
                    }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response?.data?.success === "Registrant added successfully.") {
                    toast?.success(response?.data?.success);
                }
                setIsDisable(false);
                setShow(false);
                Notes();
                handleClose();

            } catch (error) {
                if (error?.response?.data?.message === "Request failed with status code 401") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin/login");
                    toast.error(`Unauthozied user !`);
                } else if (error.response.data.message === "Registrant already exists in the same firm") {
                    toast.error("Registrant already exists in the same firm.");
                    setIsDisable(false);
                }
                setShow(false);
                setIsDisable(false);
            }
        } else {
            toast.error(`Fill all the required field `);
            setIsDisable(false);
        }
    };



    //   const handleKeyOnlyLatter = (e) => {
    //     const char = String.fromCharCode(e.keyCode || e.which);
    //     if (!/^[a-zA-Z\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(char)) {
    //       e.preventDefault();
    //     }
    //   };

//   const countryValidateField = (country, value) => {

//     if ( value== '' || value == undefined || !value) {
//         console.log("value 178",value)
//       newErrors.country = 'Country is required.';
//     } else if(value?.length>1) {
//         console.log("value 178",value)
//         newErrors.country = '';
//       delete newErrors.country;
//     }
//     console.log("value 178",value)
//     console.log('newErrors',newErrors)
  
//     setErrors(newErrors);
//     RegionCountryValidateField('country',address?.country?.name)
//   };
  
    // const validateExperienceField = (fieldName, value) => {
    //         if (fieldName === 'title') {
    //              newExperienceErrors.title = value === '' ? 'Experience title is required.' : '';
    //         } else if (fieldName === 'company_name') {
                
    //                 newExperienceErrors.company_name = value === '' ? 'Company Name is required.' : '';
    //     }
    //     setExperienceErrors(newExperienceErrors);
    // };

    // const validateVolExperienceField = (fieldName, value) => {
    //         if (fieldName === 'title') {
    //                 newErrors.titles = value === '' ? 'Title is required.' : '';
    //         }else if (fieldName === 'company_name') {
    //                 newErrors.company_name = value === '' ? 'Company Name is required.' : '';
    //     }
    //      setErrors(newErrors);
    // };
    

//     const RegionvalidateForm = () => {
//         for (const fieldName in newRegions) {
//             if (newRegions.hasOwnProperty(fieldName)) {
//                  RegionValidateField(fieldName, newRegions[fieldName]);
//         }
//         }
//         setRegionError(newRegionErrors);
        
//   };

    // const handleVolExperince = (e) => {
    //     const { name, value } = e.target;
    //     const filteredValue = value?.replace(emojiRegex, '');
    //     const trimmedValue = filteredValue?.trimStart();
    //         setNewVolunteerExperince({
    //         ...newExperince,
    //         [name]: trimmedValue,
    //         });
    //     validateVolExperienceField(name, value);
    // };

    // const handleDeleteRegion = (index) => {
    //     const updatedRegion = [...regions];
    //     const deletedRegion = updatedRegion?.splice(index, 1)[0];
    //     setRegions(updatedRegion)
    // };
    
    //   const addNewVolunteerExperince = () => {
    // setVolunteerExperince([...volunteerExperince, newVolunteerExperince]);
    // };
    
    // const handleDeleteVolunteerExperince = (index) => {
    //     const updatedVolExperince = [...volunteerExperince];
    //     const deletedVolExperiences = updatedVolExperince?.splice(index, 1)[0];
    //     setVolunteerExperince(updatedVolExperince)
    // };

//     const handleTitles = () => {
//     if (!newTitle) {
//         toast.error('Title cannot be empty');
//         return;
//     }

//     const isTitleSelected = newRegions?.titles?.some(title => title === newTitle);

//     if (isTitleSelected) {
//         setNewTitle('');
//     } else {
//         setNewRegions((prevData) => ({
//             ...prevData,
//             titles: [...(prevData?.titles || []), newTitle],
//         }));
//         setNewTitle('');
//     }
// };

//     const handleTitles = () => {
//         const isTitleSelected = regions?.some(region => region?.titles?.includes(newTitle));
//         if (isTitleSelected) {
//             toast.error('Title Already Exists');
//             setNewTitle('');
//         } else {
//             setNewRegions((prevData) => ({
//                 ...prevData,
//                 titles: [...(prevData?.titles || []), newTitle],
//             }));
//             setNewTitle('');
//         }
// };
    
    return (
        <>
         <Modal show={show} onHide={handleClose} backdrop="true" keyboard={false} size="lg" centered className='add_registrantModal'>
            <Modal.Header className="modal_header" closeButton>
                <Modal.Title className="model_title py-2 text-center">
                        
                Add Registered Individual
                        
                </Modal.Title>
            </Modal.Header>
                <Modal.Body className="modal_body">
                  <Container fluid >
                    <Row>
                        <Col lg={12} md={12} className='mt-4'>
                            <div className="mb-2 personal_info d-flex align-items-center">
                            <p className="fw-bold mx-1 mb-0 ">LinkedIn ID Verifier</p>
                            </div>       
                        </Col>     
                        <Col lg={12} md={12}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                            LinkedIn URL <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field position-relative">
                             <Form.Control
                                 className={` 
                                ${((linkedinError.linkedin_link) && (linkedin === '') || (linkedinError.linkedin_link)) && 'error_active'}
                                ${(((linkedinError.linkedin_link === '')) && (linkedin)) && 'sucess_active'}`}           
                                type="url"
                                name="linkedin_link"
                                placeholder="Enter LinkedIn URL Here"
                                disabled={isReadonly}            
                                value={linkedin}
                                onChange={handleLinkinURLChange}
                                style={{paddingRight:"173px"}}           
                            />
                              {}          
                                        <Button className="btn_notice position-absolute" style={{ padding: "2px 18px", top: '3px', right: "4px" }} disabled={isReadonly} onClick={checkRegistrant}>{isReadonly ? 'Verified' : 'Verify'}</Button>  
                                <Button className=" position-absolute border-0" style={{ padding: "2px 18px", top: '3px', right: "93px", outline: "none", backgroundColor: "transparent", color:"#0F66B5"}} onClick={handleReset}><i className="fa-solid fa-arrow-rotate-right me-1"></i></Button>        
                            </div>
                            <span style={{ color: "red" }}> {linkedinError?.linkedin_link}</span>
                        </Form.Group>
                        </Col>        
                        <Col lg={12} md={12}>
                                <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                    <p className="fw-bold mx-1 mb-0 ">Regions
                                        <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg> 
                            </p>
                                </div>       
                        </Col> 
                        <Col xxl={6} md={6} sm={12}>
                        <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Region <svg
                                            className="ms-1 mb-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="9"
                                                height="9"
                                                viewBox="0 0 9 9"
                                                fill="none"
                                            >
                                    <path
                                    d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                    fill="#F92828"
                                    />
                                </svg>
                                </Form.Label>
                                <Select
                                className={`
                                        ${((regionErrors.region) && (regionErrors.region === '') || (regionErrors.region)) && 'error_active'}
                                        ${(((regionErrors.region === '')) && (regionErrors.region)) && 'sucess_active'} ` }
                                id="region"
                                name="region"
                                label="region"
                                placeholder="Select Regions"  
                                isDisabled={formDisabled}    
                                options={options}
                                ref={selectInputRef}
                                onChange={handleRegionChange}
                                components={{ DropdownIndicator }}
                                        />
                                <span style={{ color: "red" }}>
                                            {errors.region}
                                        </span>        
                                </Form.Group>           
                        </Col>
                            <Col xxl={6} md={6} sm={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Category <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg> 
                                    </Form.Label>
                                    <CreatableSelect
                                        isMulti
                                        options={firmCategoryOptions}
                                        value={selectedOptions}
                                        onChange={handleCategoryChange}
                                        isDisabled={formDisabled}
                                        placeholder="Select  Categories "
                                        noOptionsMessage={() => 'No Category Available'}
                                        formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                                        components={{ DropdownIndicator }}

                                    />
                                    <span style={{ color: "red" }}> {errors.reginCatagores}</span>
                                </Form.Group>
                            </Col>
                            {/* <div className=" position-relative mt-1">
                                <input
                                    className={`py-2 form-control
                                        ${((regionErrors.titles) && (newRegions.titles === '') || (regionErrors.titles))|| (titleError) && 'error_active'}
                                        ${(((regionErrors.titles === '')) && (newRegions.titles))  && 'sucess_active'}`}        
                                    style={{ opacity: "0.9", width: "100%" }}
                                    name='titles'
                                    type="text"
                                    placeholder="Example: Chief Commercial Officer"      
                                    value={newTitle}
                                    disabled={formDisabled}    
                                    onChange={handleTitleChange}    
                                    
                                    onInput={(e) => {
                                        if (e.target.value.length > 30) {
                                          e.target.value = e.target.value.slice(0, 30);
                                        }
                                      }}
                                    />
                                    {formDisabled ? ( <i className="fa-solid fa-circle-plus catgorices_add" ></i>):( <i className="fa-solid fa-circle-plus catgorices_add" onClick={handleTitles}></i>)}
                                   
                                    <span style={{ color: "red" }}>
                                            {titleError?.region}
                                        </span> 
                                </div>
                                    <Col lg={12} md={12} sm={12}>
                                        <ul className=" d-flex align-items-center flex-wrap mt-2" style={{ gap: '13px' }} >
                                            {newRegions?.titles?.map((newTitle, index) => (
                                                <>
                                                    <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                                        style={{ padding: '10px', backgroundColor: '#C7E4FF', color: '#1E1E1E', borderRadius: '5px' }}>
                                                            {newTitle}
                                                        <i className="fa-solid fa-xmark ms-2 " style={{ cursor: 'pointer' }} onClick={() => { handleDeleteTitles(index) }} ></i>
                                                    </li>
                                            
                                                </> ))} 
                                            </ul>
                                    </Col>       */}
                        
                        <Col xxl={12} md={12} sm={12}>
                            <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                    Registrant terms and conditions
                                        <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                        type="text"
                                        name="term_and_condition"
                                        value={newRegions?.term_and_condition}  
                                        disabled={formDisabled}    
                                        placeholder="Enter Terms & Conditions Here"
                                        onChange={handleRegions}
                                        />
                                    </div>
                                </Form.Group>  
                                <Button className='mt-2' onClick={addNewRegions} disabled={formDisabled} > Add Regions</Button>      
                        </Col> 
                        {regions?.length > 0 &&
                            <Col sm={12}>
                            <div className=" table-responsive mt-2">
                                <table className=" table table-striped w-100">
                                <thead>
                                    <tr>
                                        <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Region </th>
                                        <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Title</th>
                                        <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Terms & Conditions </th>          
                                        <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Delete</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    regions?.map((item, index)=>{
                                    return(
                                    <tr  key={index}>
                                            <td className="px-3" style={{fontSize:'15px'}}> {item?.region}</td>
                                            <td className="text-center px-3" style={{fontSize:'15px'}}>{item?.titles}</td>
                                            <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.term_and_condition}</td>
                                            <td className="text-center px-3" style={{fontSize:'15px'}}><i className="fa-solid fa-trash" style={{cursor:'pointer', color:'red'}} onClick={() => handleDeleteRegion(index, item)}></i></td>
                                    </tr>
                                    )
                                })
                                }
                                </tbody>
                                </table>
                            </div>
                            </Col>          
                        }         
                        <Col lg={12} md={12} className='mt-4'>
                            <div className="mb-4 personal_info d-flex align-items-center">
                            <p className="fw-bold mx-1 mb-0 ">Personal Information</p>
                            </div>       
                        </Col> 
                        <Col xxl={3} lg={3} md={12} sm={12}>
                                
                        <div className="firm_box d-flex justify-content-center align-items-center"
                            style={{
                            width: "131px",
                            height: "131px",
                            border: " 2px solid rgba(237, 240, 241, 1)",
                            borderRadius: "8.037px",
                            backgroundColor: "rgba(237, 240, 241, 1)",
                            }}
                        >
                            {file ? (
                            <img
                                className="firm_picture img-fluid"
                                src={file || previewUrl}
                                alt="logo "
                                style={{
                                width: "160px",
                                height: "160px",
                                borderRadius: "8.037px",
                                }}
                            />
                        ) : (
                            <img
                                className="firm_picture img-fluid"
                                src="/asset/photo_camera.png"
                                alt="logo "
                                style={{
                                borderRadius: "8.037px",
                                backgroundColor: "rgba(237, 240, 241, 1)",
                                }}
                            />
                            )}
                        
                        </div>
                        </Col>
                        <Col xxl={9} lg={9} md={12} sm={12}>
                                                        
                            <div className="profile mt-2 ms-2">
                                <p className="text-start fw-bolder mb-0"> Profile Image</p>
                            </div>                              
                            <div className="d-flex align-items-center justify-content-start  mt-3 ms-1">
                                <Button className=" text-white btn_notice cursor-pointer" disabled={formDisabled}>
                                    <div className="position-relative">
                                        <input type="file" className="position-absolute w-100 h-100 opacity-0 cursor-pointer"  onChange={handleupload} />
                                        Upload
                                    </div>
                                </Button>
                                <Button className="btn_cancel cursor-pointer ms-3" disabled={formDisabled} onClick={handleCancelImage}>
                                     Remove
                                </Button>        
                            </div>
                                    
                        </Col>                 
                        <Col lg={12} md={12} className='mt-3'>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Registrant Name  <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((errors.registrant_name) && (formData.registrant_name === '') || (errors.registrant_name)) && 'error_active'}
                                ${(((errors.registrant_name === '')) && (formData.registrant_name)) && 'sucess_active'}`}
                                type="text"
                                name="registrant_name"
                                value={formData?.registrant_name}
                                placeholder="Enter Registrant Name Here"
                                disabled={formDisabled}            
                                 onChange={handleChange}
                                />
                                <span style={{ color: "red" }}>
                                    {errors.registrant_name}
                                </span>
                            
                            </div>
                            </Form.Group>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                            Current Job Title  <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            
                            </Form.Label>
                            <div className="input_field">
                            <Form.Control
                                type="text"
                                className={`
                                ${((errors.current_job_title) && (formData.current_job_title === '') || (errors.current_job_title)) && 'error_active'}
                                ${(((errors.current_job_title === '')) && (formData.current_job_title)) && 'sucess_active'}`}
                                name="current_job_title"
                                placeholder="Enter Current Job Title Here"
                                disabled={formDisabled}            
                                value={formData?.current_job_title || ''}
                                onChange={handleChange}
                            />
                                <span style={{ color: "red" }}>
                                    {errors.current_job_title}
                                </span>
                            </div>
                        </Form.Group>
                        </Col>



                        <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Country <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                    <div className="input_field">
                            <Select                                                
                              className={`
                                    ${(((errors.country) && (formData.country === ''))) && 'error_active'}
                                    ${((errors.country === '') && (formData.country)) && 'sucess_active'}`}
                              id="country"
                              name="country"
                              label="country"                
                            options={updatedCountries}
                            value={address?.country}                                   
                            onChange={handleCountryChange}
                             isDisabled={formDisabled}       
                             components={{ DropdownIndicator }}        
                            />

                            <span style={{ color: "red" }}>
                              {address?.country?.length<1 ? errors.country : ''}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Province <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Select
                              className={`
                                   ${(((errors.province) && (formData.province === '')) || (errors.province)) && 'error_active'}
                                   ${((errors.province === '') && (formData.province)) && 'sucess_active'}`}
                              id="province"
                              name="province"
                            options={updatedStates}
                            value={address?.state}
                            onChange={handleStateChange}        
                            isDisabled={formDisabled || isdisabled}   
                            components={{ DropdownIndicator }}                                              
                            />
                            <span style={{ color: "red" }}>
                              {errors.province}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            City 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            {/* <Form.Control
                              className={`
                                 ${((errors.city) && (formData.city === '') || (errors.city)) && 'error_active'}
                                 ${(((errors.city === '')) && (formData.city)) && 'sucess_active'}`}
                              type="text"
                              onKeyPress={handleKeyOnlyLatter}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="city"
                              placeholder="Enter City"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.city}
                            /> */}
                             <Select
                            className={`
                                ${((errors.city) && (formData.city === '') || (errors.city)) && 'error_active'}
                                ${(((errors.city === '')) && (formData.city)) && 'sucess_active'}`}
                                value={formData?.city ? { label: formData.city, value: formData.city} : null}
                                name="city"
                                id='city'
                                onChange={handleCity}
                                options={cityData}
                                isDisabled={formDisabled}
                                components={{ DropdownIndicator }}
                                            />
                            <span style={{ color: "red" }}>
                              {errors.city}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16px", letterSpacing:"0.16px"}}>
                            Location 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" > */}


                            {/* </i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                   ${((errors.location) && (formData.location === '') || (errors.location)) && 'error_active'}
                                   ${(((errors.location === '')) && (formData.location)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="location"
                              placeholder="Enter Location"
                              onChange={(e) => { handleChange(e) }}
                            value={formData.location}
                            disabled={formDisabled}               
                            />
                            <span style={{ color: "red" }}>
                              {errors.location}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Postal Code
                             {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                  ${((errors.postalCode) && (formData.postalCode === '') || (errors.postalCode)) && 'error_active'}
                                  ${(((errors.postalCode === '')) && (formData.postalCode)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              maxLength={10}
                              name="postalCode"
                              placeholder="Enter Postal Code"
                            onChange={(e) => { handleChange(e) }}
                            disabled={formDisabled}
                              value={formData.postalCode}
                            />
                            <span style={{ color: "red" }}>
                              {errors.postalCode}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>









                        <Col lg={6} md={6}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                                Number Of Connections 
                                <span className="fw-normal optional text-secondary mx-2">
                                    (Optional)
                                </span>        
                            </Form.Label>
                            <div className="input_field">
                            <Form.Control
                                type="number"
                                name="number_connections"
                                placeholder="Enter Number OF Connections Here" 
                                disabled={formDisabled}            
                                value={formData?.number_connections|| ''}
                                onChange={handleChange}            
                            />
                            </div>
                        </Form.Group>
                        </Col> 
                        <Col lg={6} md={6}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                                Number Of Followers
                               <span className="fw-normal optional text-secondary mx-2">
                                    (Optional)
                                </span>         
                            </Form.Label>
                            <div className="input_field">
                            <Form.Control
                                type="number"
                                name="number_followers"
                                placeholder="Enter Number OF Followers Here"   
                                disabled={formDisabled}           
                                value={formData?.number_followers || ''}
                                onChange={handleChange}            
                            />
                            </div>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                            Work Mail
                            <span className="fw-normal optional text-secondary mx-2">
                                (Optional)
                            </span>
                            </Form.Label>
                            <div className="input_field">
                            <Form.Control
                                type="text"
                                className={`
                                ${((errors.work_email) && (formData.work_email === '') || (errors.work_email)) && 'error_active'}
                                ${(((errors.work_email === '')) && (formData.work_email)) && 'sucess_active'}`}            
                                name="work_email"
                                placeholder="Enter Current Job Title Here"
                                value={formData?.work_email || ''}
                                disabled={formDisabled}            
                                onChange={handleChange}
                            />
                            <span style={{ color: "red" }}>{errors.work_email}</span>            
                            </div>
                        </Form.Group>
                        </Col>    
                        <Col lg={6} md={6}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                            Personal Mail
                            <span className="fw-normal optional text-secondary mx-2">
                                (Optional)
                            </span>
                            </Form.Label>
                            <div className="input_field">
                            <Form.Control
                                type="text"
                                 className={`
                                ${((errors.personal_email) && (formData.personal_email === '') || (errors.personal_email)) && 'error_active'}
                                ${(((errors.personal_email === '')) && (formData.personal_email)) && 'sucess_active'}`}           
                                name="personal_email"
                                placeholder="Enter Current Job Title Here"
                                value={formData?.personal_email || ''}
                                disabled={formDisabled}            
                                onChange={handleChange}
                            />
                           <span style={{ color: "red" }}>{errors.personal_email}</span>
                            </div>
                        </Form.Group>
                        </Col>
                        <Col lg={6} md={6}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Registrant Phone Number   
                             <span className="fw-normal optional text-secondary mx-2">
                                (Optional)
                            </span>           
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((errors.phone_number) && (formData.phone_number === '') || (errors.phone_number)) && 'error_active'}
                                ${(((errors.phone_number === '')) && (formData.phone_number)) && 'sucess_active'}`}
                                type="text"
                                name="phone_number"
                                  value={formData?.phone_number || ''} 
                                placeholder="Enter Registrant Phone Number  Here"
                                disabled={formDisabled}            
                                onChange={handleChange}
                                />
                                <span style={{ color: "red" }}>
                                   {errors.phone_number} 
                                </span>
                            
                            </div>
                            </Form.Group>
                        </Col>
                        
                        <Col lg={6} md={6} sm={6} >
                            <Form.Label className="form_label fw-bolder">
                                Languages
                            <span className="fw-normal optional text-secondary mx-2">
                                (Optional)
                            </span>
                            </Form.Label>
                              <div className=" position-relative" style={{ wordBreak:'break-all' }}>
                                <input
                                  style={{ opacity: "0.9", width: "100%" }}
                                  className="py-2 form-control"
                                  name='languages'
                                  type="text"
                                  value={newLanguage}
                                  disabled={formDisabled}      
                                  onChange={handleLanguageChange}   
                                  onInput={(e) => {
                                    if (e.target.value.length > 35) {
                                      e.target.value = e.target.value.slice(0, 35);
                                    }
                                  }}  
                                />
                                <i className="fa-solid fa-circle-plus catgorices_add"  onClick={handleLanguages} disabled={formDisabled}   ></i>
                              </div>
                                <Col lg={12} md={12} sm={12}>
                                    <ul className=" d-flex align-items-center flex-wrap mt-2" style={{ gap: '13px' }} >
                                        {formData?.languages?.map((language, index) => (
                                            <>
                                                <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                                    style={{ padding: '10px', backgroundColor: '#C7E4FF', color: '#1E1E1E', borderRadius: '5px' }}>
                                                    {language}
                                                    <i className="fa-solid fa-xmark ms-2 " style={{ cursor: 'pointer' }} onClick={() => { handleDeleteLanguages(index) }} ></i>
                                                </li>
                                         
                                            </> ))} 
                                        </ul>
                                </Col>      
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Form.Label className="form_label fw-bolder" style={{ wordBreak:'break-word' }}>
                                Skills
                            <span className="fw-normal optional text-secondary mx-2">
                                (Optional)
                            </span>
                            </Form.Label>
                              <div className=" position-relative">
                                <input
                                  style={{ opacity: "0.9", width: "100%" }}
                                  className="py-2 form-control"
                                  name='skills'
                                type="text"
                                disabled={formDisabled}        
                                value={newSkill}
                                  onChange={handleSkillChange}    
                                  onInput={(e) => {
                                    if (e.target.value.length > 35) {
                                      e.target.value = e.target.value.slice(0, 35);
                                    }
                                  }}  
                                />
                                <i className="fa-solid fa-circle-plus catgorices_add" onClick={handleSkills}   disabled={formDisabled}></i>
                              </div>
                              <Col lg={12} md={12} sm={12}>
                                <ul className=" d-flex align-items-center flex-wrap mt-2" style={{ gap: '13px' }}>
                                    <>
                                     {formData?.skills?.map((skill, index) => (
                                        <>
                                            <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                                style={{ padding: '10px', backgroundColor: '#C7E4FF', color: '#1E1E1E', borderRadius: '5px' }}>
                                                {skill}
                                                <i className="fa-solid fa-xmark ms-2 " style={{ cursor: 'pointer' }} onClick={() => { handleDeleteSkills(index) }} ></i>
                                            </li>
                                         
                                        </> ))}
                                              
                                    </>
                                 
                                </ul>
                              </Col>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Description <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control as="textarea"
                                    placeholder="Enter Description Here"
                                    className="personal_description"        
                                    name='description'
                                    value={formData?.description}
                                    disabled={formDisabled}        
                                    onChange={handleChange}
                                    rows={4} />
                                {/* <span style={{ color: "red" }}> {errors?.description}</span> */}
                            </div>
                            </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                       Registarnt CSA Link <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            className={`
                                                    ${((errors.registarntResourceUrl) && (formData.registarntResourceUrl === '') || (errors.registarntResourceUrl)) && 'error_active'}
                                                    ${(((errors.registarntResourceUrl === '')) && (formData.registarntResourceUrl)) && 'sucess_active'}`}
                                            name="registarntResourceUrl"
                                            placeholder="Enter Firm CSA Link "
                                            value={formData?.registarntResourceUrl || ''}
                                            disabled={formDisabled}
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}> {errors?.registarntResourceUrl}</span>
                                        
                                    </div>
                                </Form.Group>
                            </Col>     
                            {/* <Col lg={12} md={12} sm={12} >
                                <div className="mb-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                        <path d="M1.5 15C1.0875 15 0.734375 14.8531 0.440625 14.5594C0.146875 14.2656 0 13.9125 0 13.5V6C0 5.5875 0.146875 5.23438 0.440625 4.94063C0.734375 4.64687 1.0875 4.5 1.5 4.5H3V0H9V3H4.5V9H6V4.5H13.5C13.9125 4.5 14.2656 4.64687 14.5594 4.94063C14.8531 5.23438 15 5.5875 15 6V13.5C15 13.9125 14.8531 14.2656 14.5594 14.5594C14.2656 14.8531 13.9125 15 13.5 15H1.5Z" fill="#5794CB" />
                                    </svg>
                                    <span className="px-2">Firm CSA Link </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <input
                                        style={{ opacity: "0.9", width: "100%" }}
                                        className={`form-control py-2
                                      ${((errors.firmResourceUrl) || (errors.firmResourceUrl)) && 'error_active'}
                                    ${(((errors.firmResourceUrl === '')) && (formData.firmResourceUrl)) && 'sucess_active'}`}
                                        name='firmResourceUrl'
                                        type="text"
                                        value={formData?.firmResourceUrl || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                                <span style={{ color: "red" }}>{errors.firmResourceUrl}</span>
                            </Col>    */}


                            {/* education section  */}
                         <Col lg={12} md={12}>
                            <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                    <i className="fa-solid fa-school mt-1" style={{ color: '#5794cb' }}></i>
                                <p className="fw-bold mx-3 mb-0 ">Educations</p>
                            </div>       
                        </Col>                           
                        <Col lg={12} md={12}>
                          <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                School Name <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((educationErrors.school_name) && (newEducation.school_name === '') || (educationErrors.school_name)) && 'error_active'}
                                ${(((educationErrors.school_name === '')) && (newEducation.school_name)) && 'sucess_active'}`}
                                type="text"
                                name="school_name"
                                value={newEducation?.school_name}
                                placeholder="Enter Title Here"
                                disabled={formDisabled}          
                                onChange={handleEducation}
                                />
                                <span style={{ color: "red" }}>
                                    {educationErrors?.school_name}
                                </span>
                            
                            </div>
                            </Form.Group>      
                        </Col>  
                        <Col lg={12} md={12}>
                        <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder ">
                                School LinkedIn URL  <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((educationErrors.linkedin_school_url) && (newEducation.linkedin_school_url === '') || (educationErrors.linkedin_school_url)) && 'error_active'}
                                ${(((educationErrors.linkedin_school_url === '')) && (newEducation.linkedin_school_url)) && 'sucess_active'}`}          
                                type="url"
                                name='linkedin_school_url' 
                                disabled={formDisabled}            
                                placeholder="Enter School LinkedIn URL "   
                                value={newEducation?.linkedin_school_url }
                                onChange={handleEducation}               
                             />
                                <span style={{ color: "red" }}> {educationErrors?.linkedin_school_url}</span>
                            </div>
                    </Form.Group>  
                        <Button className='mt-2' onClick={addNewEducation} disabled={formDisabled} > Add Education</Button>          
                        </Col> 
                            
                    {education?.length > 0 &&
                        <Col sm={12}>
                           <div className=" table-responsive mt-2">
                            <table className=" table table-striped w-100">
                              <thead>
                                    <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>School Name </th>
                                    <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>School LinkedIn URL </th>           
                                    <th className="px-3 text-center" style={{minWidth:'100px', fontSize:'15px'}}>Delete</th> 
                              </thead>
                              <tbody>
                              {
                                education?.map((item, index)=>{
                                  return(
                                <tr  key={index}>
                                        <td className="text-center px-3" style={{fontSize:'15px'}}>{item?.school_name}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.linkedin_school_url}</td>
                                        <td className="text-center px-3" style={{fontSize:'15px'}}><i className="fa-solid fa-trash" style={{cursor:'pointer', color:'red'}} onClick={() => handleEducationDelete(index, item)}></i></td>
                                </tr>
                                )
                              })
                            }
                              </tbody>
                            </table>
                           </div>
                        </Col>
                    } 

                         {/* education section  */}
                     {/* Experiences        */}
                         <Col lg={12} md={12}>
                            <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                <i className="fa-solid fa-hand-holding-heart" style={{color:'#5794cb'}}></i>
                                <p className="fw-bold mx-3 mb-0 "> Experiences</p>
                            </div>       
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Title <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((experienceErrors.title) && (newExperince.title === '') || (experienceErrors.title)) && 'error_active'}
                                ${(((experienceErrors.title === '')) && (newExperince.title)) && 'sucess_active'}`}
                                type="text"
                                name="title"
                                value={newExperince?.title}
                                placeholder="Enter Title Here"
                                disabled={formDisabled}           
                                onChange={handleExperince}
                                />
                                <span style={{ color: "red" }}>
                                            {newExperienceErrors.title}
                                </span>
                            
                            </div>
                            </Form.Group>
                            <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Company Name <svg
                                    className="ms-1 mb-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="none"
                                >
                                    <path
                                    d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                    fill="#F92828"
                                    />
                                </svg>
                                </Form.Label>
                                <div className="input_field">
                                    <Form.Control
                                    className={`
                                    ${((experienceErrors.company_name) && (newExperince.company_name === '') || (experienceErrors.company_name)) && 'error_active'}
                                    ${(((experienceErrors.company_name === '')) && (newExperince.company_name)) && 'sucess_active'}`}
                                    type="text"
                                    name="company_name"
                                    value={newExperince?.company_name}
                                    placeholder="Enter Company Name Here"
                                    disabled={formDisabled}       
                                    onChange={handleExperince}
                                    />
                                    <span style={{ color: "red" }}>
                                            {newExperienceErrors.company_name}
                                    </span>
                                
                                </div>
                            </Form.Group>
                            <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Description 
                                    <span className="fw-normal optional text-secondary mx-2">
                                        (Optional)
                                    </span>    
                                </Form.Label>
                                <div className="input_field">
                                        <Form.Control as="textarea"
                                        // personal_description
                                            className={`personal_description
                                                    ${((experienceErrors.company_description) && (newExperince.company_description === '') || (experienceErrors.company_description)) && 'error_active'}
                                                    ${(((experienceErrors.company_description === '')) && (newExperince.company_description)) && 'sucess_active'}`}    
                                            placeholder="Enter Company Description Here"
                                            disabled={formDisabled}
                                            name='company_description'
                                            value={newExperince?.company_description}
                                            onChange={handleExperince}
                                            rows={4}
                                            />
                                        <span style={{ color: "red" }}>
                                            {newExperienceErrors.company_description}
                                        </span>
                                </div>
                            </Form.Group>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Location <span className="fw-normal optional text-secondary mx-2">
                                        (Optional)
                                        </span>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                type="text"
                                name="location"
                                value={newExperince?.location}
                                placeholder="Enter Title Here"
                                disabled={formDisabled}            
                                onChange={handleExperince}
                                />
                            </div>
                            </Form.Group> 
                            <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Date <span className="fw-normal optional text-secondary mx-2">(Optional) </span>
                                </Form.Label>
                                <div className="input_field">
                                    <Form.Control
                                    type="text"
                                    name="date"
                                    disabled={formDisabled}       
                                    value={newExperince?.date}
                                    placeholder="Enter Start Date Here  Example : Start Date - End Date"
                                    onChange={handleExperince}
                                />
                                </div>
                            </Form.Group>        
                            
                            <Button className='mt-2' onClick={addNewExperince} disabled={formDisabled}> Add Experiences</Button>    
                        </Col> 
                     {experiences?.length > 0 &&
                        <Col sm={12}>
                           <div className=" table-responsive mt-2">
                            <table className=" table table-striped w-100">
                              <thead>
                                    <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Title </th>
                                    <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Company Name  </th>
                                    <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Description </th>
                                    <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Location </th>
                                     <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Date </th>           
                                    <th className="px-3 text-center" style={{minWidth:'100px', fontSize:'15px'}}>Delete</th> 
                              </thead>
                              <tbody>
                              {
                                experiences?.map((item, index)=>{
                                  return(
                                <tr  key={index}>
                                  <td className="px-3" style={{fontSize:'15px'}}> {item?.title}</td>
                                        <td className="text-center px-3" style={{fontSize:'15px'}}>{item?.company_name}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.company_description}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.location}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.date}</td>
                                        <td className="text-center px-3" style={{fontSize:'15px'}}><i className="fa-solid fa-trash" style={{cursor:'pointer', color:'red'}} onClick={() => handleDeleteExperinces(index, item)}></i></td>
                                </tr>
                                )
                              })
                            }
                              </tbody>
                            </table>
                           </div>
                        </Col>
                      }      

                       {/* Experiences        */}
                        <Col lg={12} md={12} className='mt-3'>
                            <div className='d-flex align-items-center personal_info'> 
                                <p className="fw-bold mx-1 mb-0">Company Information</p>
                            </div>       
                        </Col> 
                        <Col lg={12} md={12} >
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Headline <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                            </Form.Label>
                                <div className="input_field">
                                    <Form.Control as="textarea"      
                                         className='personal_description'
                                    placeholder='Enter Company Headline Here' type='text' name='headline' rows={4} value={formData?.headline} onChange={handleChange} disabled={formDisabled}/>        
                                {/* <span style={{ color: "red" }}>
                                    {errors.headline}
                                </span> */}
                            
                            </div>
                            </Form.Group>
                        </Col>   
                        <Col lg={12} md={12} >
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Current Company Name  <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((errors.current_company_name) && (formData.current_company_name === '') || (errors.current_company_name)) && 'error_active'}
                                ${(((errors.current_company_name === '')) && (formData.current_company_name)) && 'sucess_active'}`}
                                type="url"
                                name="current_company_name"
                                value={formData?.current_company_name}        
                                placeholder="Enter Current Company Name Here"
                                disabled={formDisabled}            
                                onChange={handleChange}
                                />
                                <span style={{ color: "red" }}>
                                    {errors.current_company_name}
                                </span>
                            
                            </div>
                            </Form.Group>
                        </Col>  
                        <Col lg={12} md={12} >
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Company LinkedIn Url <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((errors.company_url) && (formData.company_url === '') || (errors.company_url)) && 'error_active'}
                                ${(((errors.company_url === '')) && (formData.company_url)) && 'sucess_active'}`}
                                type="text"
                                name="company_url"
                                value={formData?.company_url}           
                                placeholder="Enter Current Company Name Here"
                                disabled={formDisabled}           
                                onChange={handleChange}
                                />
                                <span style={{ color: "red" }}>
                                    {errors.company_url}
                                </span>
                            
                            </div>
                            </Form.Group>
                        </Col>                           
                         <Col lg={12} md={12} >
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Company Industry <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((errors.company_industry) && (formData.company_industry === '') || (errors.company_industry)) && 'error_active'}
                                ${(((errors.company_industry === '')) && (formData.company_industry)) && 'sucess_active'}`}
                                type="text"
                                name="company_industry"
                                value={formData?.company_industry}        
                                placeholder="Enter Current  Company Industry Here"
                                disabled={formDisabled}            
                                onChange={handleChange}
                                />
                                <span style={{ color: "red" }}>
                                    {errors.company_industry}
                                </span>
                            
                            </div>
                            </Form.Group>
                         </Col> 
                            {/* Volunteer Experiences */}
                        {/* <Col lg={12} md={12}>
                            <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                 <i className="fa-solid fa-hand-holding-heart" style={{color:'#5794cb'}}></i>
                                <p className="fw-bold mx-3 mb-0 "> Volunteer Experiences</p>
                            </div>       
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Role <svg
                                className="ms-1 mb-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                            >
                                <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                                />
                            </svg>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                className={`
                                ${((errors.role) && (newVolunteerExperince.role === '') || (errors.role)) && 'error_active'}
                                ${(((errors.role === '')) && (newVolunteerExperince.role)) && 'sucess_active'}`}
                                type="text"
                                name="role"
                                value={newVolunteerExperince?.role}
                                placeholder="Enter Role Here"
                                disabled             
                                onChange={handleVolExperince}
                                />
                                <span style={{ color: "red" }}>
                                    {errors.role}
                                </span>
                            
                            </div>
                            </Form.Group>
                            <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Company Name <svg
                                    className="ms-1 mb-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="none"
                                >
                                    <path
                                    d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                    fill="#F92828"
                                    />
                                </svg>
                                </Form.Label>
                                <div className="input_field">
                                    <Form.Control
                                    className={`
                                    ${((errors.company_name) && (newVolunteerExperince.company_name === '') || (errors.company_name)) && 'error_active'}
                                    ${(((errors.company_name === '')) && (newVolunteerExperince.company_name)) && 'sucess_active'}`}
                                    type="text"
                                    name="company_name"
                                    value={newVolunteerExperince?.company_name}
                                    placeholder="Enter Company Name Here"
                                    disabled        
                                    onChange={handleVolExperince}
                                    />
                                    <span style={{ color: "red" }}>
                                        {errors.company_name}
                                    </span>
                                
                                </div>
                            </Form.Group>
                            <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Description <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                                </Form.Label>
                                <div className="input_field">
                                    <Form.Control as="textarea" placeholder="Enter Company Description Here" name='company_description' disabled  value={newVolunteerExperince?.company_description} onChange={handleExperince} rows={4}  className='personal_description'/>
                                    <span style={{ color: "red" }}> 
                                    </span>
                                </div>
                            </Form.Group>
                            <Form.Group className="Name py-2">
                            <Form.Label className="form_label fw-bolder">
                                Location <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                            </Form.Label>
                            <div className="input_field">
                                <Form.Control
                                type="text"
                                name="location"
                                value={newVolunteerExperince?.location}
                                disabled            
                                placeholder="Enter Title Here"
                                onChange={handleVolExperince}
                                />
                            </div>
                            </Form.Group> 
                            <Form.Group className="Name py-2">
                                <Form.Label className="form_label fw-bolder">
                                    Date <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                                </Form.Label>
                        <div className="input_field">
                            <Form.Control
                            type="date"
                            name="date"
                            disabled                
                            value={newVolunteerExperince?.date}
                            placeholder="Enter Date Here"
                            onChange={handleVolExperince}
                            />
                        </div>
                            </Form.Group>       
                            <Button className='btn_add mt-2' onClick={addNewVolunteerExperince} disabled >Add Experiences</Button>    
                        </Col> 
                     {volunteerExperince?.length > 0 &&
                        <Col sm={12}>
                           <div className=" table-responsive mt-2">
                            <table className=" table table-striped w-100">
                              <thead>
                                    <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Title </th>
                                    <th className="px-3 text-center" style={{minWidth:'150px', fontSize:'15px'}}>Company Name  </th>
                                    <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Description </th>
                                    <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Location </th>
                                     <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Date </th>           
                                    <th className="px-3 text-center" style={{minWidth:'100px', fontSize:'15px'}}>Delete</th> 
                              </thead>
                              <tbody>
                              {
                                volunteerExperince?.map((item, index)=>{
                                  return(
                                <tr  key={index}>
                                  <td className="px-3" style={{fontSize:'15px'}}> {item?.title}</td>
                                        <td className="text-center px-3" style={{fontSize:'15px'}}>{item?.company_name}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.company_description}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.location}</td>
                                        <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.date}</td>
                                        <td className="text-center px-3" style={{fontSize:'15px'}}><i className="fa-solid fa-trash" style={{cursor:'pointer', color:'red'}} onClick={() => handleDeleteVolunteerExperince(index, item)}></i></td>
                                </tr>
                                )
                              })
                            }
                              </tbody>
                            </table>
                           </div>
                         </Col>          
                    }  */}
                       
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-start border-0">
                    {isDisable ? (
                        <Button className="btn-primary btn_notice"  disabled>
                           Add Reg. Individual <i className="fa-solid fa-spinner fa-spin ms-1"></i>
                        </Button>
                    ) : (
                        <Button className="btn-primary btn_notice" onClick={handleRegistrantSubmit} disabled={formDisabled || isDisable}>
                             Add Reg. Individual
                        </Button>      
                    )}
                    
                    
                  <Button className="btn_cancel" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
          </Modal>  
        </>
    )
};
export default RegistrantAdd;

