import React, { useState, useEffect} from 'react';
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import CreatableSelect from 'react-select/creatable';
import Select, { components }  from "react-select";
import toast from 'react-hot-toast';

const AdminAddRegions = ({ token, modelshow, setModelShow, firmId }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [firmCategoryOptions, setFirmCategoryOptions] = useState([]);
    const [countries, setCountries] = useState();
    const [updatedStates, setUpdatedStates] = useState();
    const navigate = useNavigate();
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    let resionCountryName = "";
    let resionStateName = "";
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    const [newRegion, setNewRegion] = useState({
        regionCountry: '',
        regionState: '',
        categories: [],
        termAndConditions: '',

    });

    const [resionAddress, setResionAddress] = useState({
        country: '',
        state: '',
    });

    const [errors, setErrors] = useState({
        regionCountry: '',
        regionState: '',
        categories: '',
        termAndConditions: '',
    });
    let newErrors = { ...errors };

    const handleClose = () => {
        setModelShow(false);
    };

    // onchange
    const RegionHandleAddressChange = (newAddress) => {
        setResionAddress(newAddress);
    };

    const RegionHandleCountryChange = (value) => {
        stateApi(value);
        setDisable(false);
        if (value) {
            resionCountryName = value.label;
            newRegion.regionCountry = resionCountryName
            RegionCountryValidateField('regionCountry', newRegion.regionCountry);
            setNewRegion({
                ...newRegion,
                regionCountry: resionCountryName,
                regionState: '',
            });
            RegionHandleAddressChange({ country: value, state: '' });
        }
    };

    const RegionHandleStateChange = (label) => {
        if (label) {
            setNewRegion({
                ...newRegion,
                regionState: label?.label,
            });
            resionStateName = label.label;

            RegionStateValidateField('regionState', resionStateName);
            RegionHandleAddressChange({ ...resionAddress, state: label });
        }
    };

    const handleCategoryChange = (newValue) => {
        const options = Array?.isArray(newValue) ? newValue : [];
        const filteredOptions = options.filter(option => {
            const trimmedLabel = option?.label?.trimEnd();
            return trimmedLabel === option?.label;
        });
        const categories = filteredOptions?.map(option => option?.label);
        setSelectedOptions(filteredOptions);
        ValidateCategory('categories', categories);
        setNewRegion({
            ...newRegion,
            categories: categories,
        });
        

    };
// console.log('errors',errors);
// console.log('newRegion',newRegion);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const filteredValue = value.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setNewRegion((prevData) => ({
            ...prevData,
            [name]: trimmedValue,
        }));
        ValidateField(name, value);
    };

    // validation
    const RegionCountryValidateField = (country, value) => {
        if (country === 'regionCountry') {

            newErrors.regionCountry = value === '' ? 'Country is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);
        setLoading(false);

    };

    const RegionStateValidateField = (fieldName, value) => {
        if (fieldName === 'regionState') {
            newErrors.regionState = value === '' ? 'Province is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);
        setLoading(false);
    };

    const ValidateCategory = (fieldName, value) => {
        if (fieldName === 'categories') {
            newErrors.categories = value?.length === 0 ? 'At least one category must be selected.' : '';
            setErrors(newErrors);
        }

        setErrors(newErrors);
        setLoading(false);
        
    };

    const ValidateField = (fieldName, value) => {
        if (fieldName === 'termAndConditions') {
            if (value && value?.length > 50) {
                newErrors.termAndConditions = 'Term and Conditions must not exceed 50 characters.';
            } else {
                newErrors.termAndConditions = '';
            }
        }
        setErrors(newErrors);
        setLoading(false);
    };

    const RegionvalidateForm = () => {
        for (const fieldName in newRegion) {
            if (newRegion.hasOwnProperty(fieldName)) {
                RegionCountryValidateField(fieldName, newRegion[fieldName]);
                RegionStateValidateField(fieldName, newRegion[fieldName]);
                ValidateCategory(fieldName, newRegion[fieldName]);
                ValidateField(fieldName, newRegion[fieldName]);
            }
        }
        setErrors(newErrors);
        setLoading(false);
    };

    //api   
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            const options = res?.data?.map(province => ({
                value: province._id,
                label: province.name
            }));
            setCountries(options);
        }).catch((error) => {
            console.error('Error fetching province data:', error);
        });

    }, [modelshow])

    const stateApi = (value) => {
        // if (logindata?.token) {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Id': value.value,
            },
        }).then((res) => {
            const options1 = res?.data?.map(name => ({
                value: name._id,
                label: name.name
            }));
            setUpdatedStates(options1);
        }).catch((error) => {
            console.error('Error fetching province data:', error);
        });
        // }
    };

    const firmCategories = () => {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm/category-filter`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                const categoryOptions = response?.data?.data?.map(category => ({
                    value: category?._id,
                    label: category?.category
                }));
                setFirmCategoryOptions(categoryOptions);
            }).catch((error) => {
                if (error?.response?.status == 404) {
                    navigate('/admin/not-found')
                } else if (error?.response?.data?.messsage === "Unauthozied User") {
                    toast.error(error?.response?.data?.messsage);
                }
                console.error('Error fetching firm Categories:', error);
            });
    };

    useEffect(() => {
        firmCategories();
    }, [modelshow]);

    const handleCancel = () => {
        setModelShow(false);
        setNewRegion({
            region_name: '',
            region_country: '',
            categories: [],
            term_and_conditions: '',
        });
        setSelectedOptions([]);
        setResionAddress({
            country: '',
            state: '',
        });

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        RegionvalidateForm();
        if (!Object.values(newErrors).some((error) => error !== (''))) {
            setLoading(true)
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/region/${firmId}`,
                    {
                        name: newRegion?.regionState,
                        country: newRegion?.regionCountry,
                        categories: newRegion?.categories,
                        terms_and_conditions: newRegion?.termAndConditions,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (response?.data?.message === "Region successfully added") {
                    toast.success(response?.data?.message);  
                    setModelShow(false);
                    handleCancel();
                    setLoading(false);
                }
            } catch (error) {
                if (error?.response?.data?.message == "Region already exists") {
                    toast.error(`${error?.response?.data?.message}`);
                    setModelShow(false);
                    setLoading(false);
                } else if (error?.response?.data?.messsage === "Unauthozied User") {
                    localStorage.removeItem('loginAdmin');
                    navigate("/admin");
                    toast.error(`Unauthozied user !`);
                    setLoading(false);
                };
                setLoading(false);
            }
        };
    };

    const DropdownIndicator = (props) => {
        return components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ? <i class="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
          </components.DropdownIndicator>
        );
      };
    

    return (
        <>
            <Modal size="lg" centered show={modelshow} onHide={handleClose} >

                <Modal.Body className="p-0">
                    <Container fluid className="">
                        <Row>
                            <div className="model_title text-center fw-bolder mb-3 d-flex justify-content-between" style={{ backgroundColor: 'rgb(16 104 183)', borderRadius: '6px 6px 0 0' }}>
                                <h4
                                    className="title mb-0 py-2"
                                    style={{
                                        color: "#fff",
                                    }}
                                >
                                    Region Firm
                                </h4>
                                <Button style={{ color: 'black', background: '#aed0f2', lineHeight: '18px' }} onClick={handleCancel} className='my-2' >X</Button>
                            </div>
                            <Col Col lg={12} md={12}>
                                <Form className="row g-1">

                                    <Col sm={12}>
                                        <div>
                                            <h5 style={{ color: '#1068b7' }} className=" mb-0 d-inline"> <span className=" d-inline-block pb-2 mt-4" style={{ borderBottom: "3px solid #1068b7" }}>Region</span> </h5>
                                        </div>
                                    </Col>


                                    <Col xxl={6} md={6} sm={12}>
                                        <Form.Group className="Name py-2">
                                            <Form.Label className="form_label fw-bolder">
                                                Country <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                                            </Form.Label>
                                            <div className="input_field">
                                                <Select
                                                    className={`
                                                    ${(((errors.regionCountry) && (newRegion.regionCountry == '')) || (errors.regionCountry)) && 'error_active'}
                                                    ${((errors.regionCountry == '') && (newRegion.regionCountry)) && 'sucess_active'}`}
                                                    id="regionCountry"
                                                    name="regionCountry"
                                                    options={countries}
                                                    value={resionAddress?.country}
                                                    isSearchable
                                                    onChange={(value) => RegionHandleCountryChange(value)}
                                                    components={{ DropdownIndicator }}
                                                />
                                                <span style={{ color: "red" }}>
                                                    {errors.regionCountry}
                                                </span>
                                            </div>
                                        </Form.Group>

                                    </Col>

                                    <Col xxl={6} md={6} sm={12}>
                                        <Form.Group className="Name py-2">
                                            <Form.Label className="form_label fw-bolder">
                                                Province <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                                            </Form.Label>
                                            <div className="input_field">
                                                {
                                                    <Select
                                                        className={`
                                                        ${(((errors.regionState) && (newRegion.regionState == '')) || (errors.regionState)) && 'error_active'}
                                                        ${((errors.regionState == '') && (newRegion.regionState)) && 'sucess_active'}`}
                                                        id="regionState"
                                                        name="regionState"
                                                        value={resionAddress?.state}
                                                        options={updatedStates}
                                                        onChange={RegionHandleStateChange}
                                                        isSearchable
                                                        isDisabled={disable}
                                                        components={{ DropdownIndicator }}
                                                    />
                                                }
                                                <span style={{ color: "red" }}>
                                                    {errors.regionState}
                                                </span>
                                            </div>
                                        </Form.Group>

                                    </Col>

                                    <Col xxl={6} md={6} sm={12}>
                                        <Form.Group className="Name py-2">
                                            <Form.Label className="form_label fw-bolder">
                                                Categories  <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                                            </Form.Label>
                                            <div className="input_field">
                                                <CreatableSelect
                                                    className={` 
                                                        ${(((errors.categories) && (newRegion.categories == '')) || (errors.categories)) && 'error_active'}
                                                        ${((errors.categories == '') && (newRegion.categories)) && 'sucess_active'}`}
                                                    isMulti
                                                    options={firmCategoryOptions}
                                                    value={selectedOptions}
                                                    name='categories'
                                                    onChange={handleCategoryChange}                                                   
                                                    placeholder="Select  Categories "
                                                    noOptionsMessage={() => 'No Category Available'}
                                                    formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                                                    components={{ DropdownIndicator }}

                                                />
                                                <span style={{ color: "red" }}> {errors.categories}</span>
                                            </div>
                                            



                                        </Form.Group>
                                       
                                    </Col>
                                    <Col xxl={6} md={6} sm={12}>
                                        <Form.Group className="Name py-2">
                                            <Form.Label className="form_label fw-bolder">
                                                Term And Condition
                                            </Form.Label>
                                            <div className="input_field">
                                                <div className=" position-relative">
                                                    <Form.Control
                                                        className={`
                                                            ${(((errors.termAndConditions) && (newRegion.termAndConditions.length > 0)) || (errors.termAndConditions)) && 'error_active'}
                                                            ${((errors.termAndConditions == '') && (newRegion.termAndConditions.length > 0)) && 'sucess_active'}`}
                                                        type="text"
                                                        style={{
                                                            borderRadius: "5px",
                                                            border: "1.5px solid #E1DEDE",
                                                            paddingRight: '33px',
                                                        }}
                                                        onChange={handleChange}
                                                        name="termAndConditions"
                                                        placeholder="Enter Term and condition"
                                                        value={newRegion?.termAndConditions}
                                                    />
                                                </div>
                                                <span style={{ color: "red" }}>
                                                    {errors?.termAndConditions}
                                                </span>
                                            </div>
                                        </Form.Group>

                                    </Col>

                                    

                                    <Col xxl={6} md={6} sm={12}></Col>
                                </Form>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-start border-0">
                    {loading ? <Button disabled className="btn-primary btn_notice" onClick={handleSubmit}> Add Region <i className="fa-solid fa-spinner fa-spin"></i> </Button> 
                        :
                        <Button disabled={loading} className="btn-primary btn_notice" onClick={handleSubmit}>
                            Add Region
                        </Button>
                    }                                    
                    <Button className="btn_cancel" onClick={handleCancel}> Cancel </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};
export default AdminAddRegions;