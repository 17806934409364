import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Country, State } from "country-state-city";
import { BallTriangle } from 'react-loader-spinner';
import Select from 'react-select';
import toast from 'react-hot-toast';
import Swal from "sweetalert2";
import { set } from 'lodash';

const AdminsersForm  = ({ editUserId, sendeditOpen, sendEditOpenData }) => {

  let loginData = JSON.parse(window.localStorage.getItem("loginAdmin"));
  const { id } = useParams();
  // const id =editUserId
  let token = loginData.Token;
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoding] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [phoneOption, setPhoneOption] = useState("");
  const [isdisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

 const filterCountry = ["Afghanistan","Albania","Algeria","Andorra","Angola","Antigua and Barbuda","Argentina","Armenia","Australia",
 "Austria","Azerbaijan","Bahamas","Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bhutan","Bolivia","Bosnia and Herzegovina",
 "Botswana","Brazil","Brunei","Bulgaria","Burkina Faso","Burundi","Cabo Verde","Cambodia","Cameroon","Canada","Central African Republic","Chad","Chile",
 "China","Colombia","Comoros","Congo","Costa Rica","Croatia","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica",
 "Dominican Republic","East Timor (Timor-Leste)","Ecuador","Egypt","El Salvador","Equatorial Guinea","Eritrea","Estonia","Eswatini",
 "Ethiopia","Fiji","Finland","France","Gabon","Gambia","Georgia","Germany","Ghana","Greece","Grenada","Guatemala","Guinea","Guinea-Bissau",
 "Guyana","Haiti","Honduras","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Israel","Italy","Ivory Coast","Jamaica","Japan",
 "Jordan","Kazakhstan","Kenya","Kiribati","Kosovo","Kuwait","Kyrgyzstan","Laos","Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein",
 "Lithuania","Luxembourg","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Marshall Islands","Mauritania","Mauritius","Mexico","Micronesia",
 "Moldova","Monaco","Mongolia","Montenegro","Morocco","Mozambique","Myanmar (Burma)","Namibia","Nauru","Nepal","Netherlands","New Zealand","Nicaragua",
 "Niger","Nigeria","North Korea","North Macedonia (formerly Macedonia)","Norway","Oman","Pakistan","Palau","Palestine","Panama","Papua New Guinea",
 "Paraguay","Peru","Philippines","Poland","Portugal","Qatar","Romania","Russia","Rwanda","Saint Kitts and Nevis","Saint Lucia",
 "Saint Vincent and the Grenadines","Samoa","San Marino","Sao Tome and Principe","Saudi Arabia","Senegal","Serbia","Seychelles",
 "Sierra Leone","Singapore","Slovakia","Slovenia","Solomon Islands","Somalia","South Africa","South Korea","South Sudan","Spain",
 "Sri Lanka","Sudan","Suriname","Sweden","Switzerland","Syria","Taiwan","Tajikistan","Tanzania","Thailand","Togo","Tonga","Trinidad and Tobago",
 "Tunisia","Turkey","Turkmenistan","Tuvalu","Uganda","Ukraine","United Arab Emirates","United Kingdom","United States","Uruguay","Uzbekistan",
 "Vanuatu","Vatican City","Venezuela","Vietnam","Yemen","Zambia","Zimbabwe"];

  const [formData, setFormdata] = useState({
    username: '',
    organization: '',
    fullname: '',
    emailaddress: '',
    website: '',
    address_contact: '',
    city_town: '',
    postal_code: '',
    phone_code:'',
    phone: '',
    fax: '',
    company_description: '',
    country: '',
    province: '',
    industry_preferences: '',
    gender: ''
  });

  const [editErrors, setErrors] = useState({
    username: '',
    organization: '',
    fullname: '',
    emailaddress: '',
    website: '',
    address: '',
    province: '',
    address_contact: '',
    city_town: '',
    postal_code: '',
    phone_code:'',
    phone: '',
    fax: '',
    company_description: '',
    industry_preferences: '',
  })

  let dase = [];

   useEffect(() => {
     document.title = "PCMA || PCMA Members Update";
   }, []);

  const toggleStatus = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-status/${id}`, {
        status: !formData.status
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      setFormdata({
        ...formData,
        status: !formData.status
      });
      toast.success(response?.data?.message)

    } catch (error) {
      console.log('apiErrors', error);
    }
  }

  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;


  const handleChange = (e) => {
    const { name, value } = e.target;

    const filteredValue = value.replace(emojiRegex, '');
    setFormdata({ ...formData, [name]: filteredValue, });
    validateField(name, filteredValue);
  }

  useEffect(() => {
 const filteredCountries = Country.getAllCountries().filter(country => filterCountry.includes(country.name));
    setCountries(filteredCountries.map(country => ({ value: country.isoCode, label: country.name ,phonecode:country.phonecode })));
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const countryPhoneCode = selectedOption.phonecode;
     setFormdata(prevState => ({
    ...prevState,
    phone_code: countryPhoneCode, 
  }));


 
    const countryStates = State.getStatesOfCountry(selectedOption.value);
    setStates(countryStates.map(state => ({ value: state.isoCode, label: state.name })));
    setSelectedState(null);
    setIsDisabled(false);

  };

  const handleStateChange = (selectedOption) => { setSelectedState(selectedOption); };
  
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked === true) {
      setSelectedCheckboxes([
        "Private Placements Mining",
        "Private Placements Oil and Gas",
        "Private Placements - Other",
        "Portfolio Management with Exempt Clients",
        "Mergers and Acquisitions",
        "Private Placements - Real Estate",
        "Distributor of Exempt Investments",
        "Other"

      ]);
      editErrors.industry_preferences = ''
    }
    else {
      setSelectedCheckboxes([]);
      editErrors.industry_preferences = 'Industry Preference is required.'
    }
  
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    dase = selectedCheckboxes?.includes(value)
      ? selectedCheckboxes.filter((checkbox) => checkbox !== value)
      : [...selectedCheckboxes, value];
    validateField('industry_preferences', value);
    setSelectedCheckboxes(dase);

   
  };

  let validateField = (fieldName, value) => {
    if (fieldName === 'username') { editErrors.username = value?.trim() === '' ? 'Please Type Username.' : '' };
    if (fieldName === 'organization') { editErrors.organization = value?.trim() === '' ? 'Please Type Organization.' : '' };
    if (fieldName === 'fullname') { editErrors.fullname = value?.trim() === '' ? 'Please Type Fullname.' : '' };
    if (fieldName === 'emailaddress') { editErrors.emailaddress = value?.trim() === '' ? 'Please Type Email address.' : !isValidEmail(value) ? 'Invalid email address.' : '' };
  if (fieldName === 'website') {
    const urlPattern = /^(http:\/\/www.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    if (value !== undefined && value?.trim() !== '') {
        editErrors.website = (urlPattern.test(value) ? '' : 'Please enter a valid URL! Example: http://www.example.com or www.example.com');
    } else {
        editErrors.website = ''; 
    }
}
   
    if (fieldName === 'address') { editErrors.address = value?.trim() === '' ? 'Please Type address.' : '' };
    if (fieldName === 'city_town') { editErrors.city_town = value?.trim() === '' ? 'Please Type city_town.' : '' };
    if (fieldName === 'postal_code') {
      let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
      if (value.length =='') {
        editErrors.postal_code = 'Postal code is required.'
      }
      else if (!pattern.test(value)) {
        editErrors.postal_code = 'Invalid  postal code';
      } else {
        editErrors.postal_code = ''
      }
    }
     if (fieldName === 'industry_preferences') {
      editErrors.industry_preferences = value?.length === 0 ? 'The Industry_Preferences field is required' : '';
    }
    if (fieldName === 'company_description') {
      if(value?.length <= 10){
         editErrors.company_description = 'The Company Description should be minmum 10 character required.'
      }else if(value?.length > 150){
        editErrors.company_description = 'The Company Description should be maximum 150 character required.'
      }else{
        editErrors.company_description=''
      }
    }
    if (fieldName === 'phone') { 

    if(value===''){
      editErrors.phone = 'Phone number is required.';
    }
    else if (value.length < 14) {
      editErrors.phone =  'Phone number minimum 10 digit ';
    }
    else{
      editErrors.phone =  '';
    }

     };
    // if (fieldName === 'fax') { editErrors.fax = value?.trim() === '' ? 'Please Type fax.' : '' };
    setErrors(editErrors);
  }

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[0-9]+$/.test(char)) {
      e.preventDefault();
    }
  };
  

  const handlePaste = (e) => {
     e.preventDefault(); 
    const paste = e.clipboardData.getData('text/plain')
      const name = paste.replace(/[0-9\-.+]/g, '');
    setFormdata(prevState => ({
    ...prevState,
    fullname: name, 
  }));
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function phoneCodeFormat(input) {
  input = input?.replace(/\D/g, "");
  input = input.length > 0 && !input.startsWith("+") ? "+" + input : input;
  return input;
}

  function phoneFormat(input) {
    input = input?.replace(/\D/g, "");
    input = input?.trim();
    input = input?.substring(0, 10);
    var size = input?.length;
  if (size === 0) {
    input = input;
  } else if (size < 4) {
    input = "(" + input;
  } else if (size < 7) {
    input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
  } else if (size <= 11) {
    input =
      "(" +
      input.substring(0, 3) +
      ") " +
      input.substring(3, 6) +
      "-" +
      input.substring(6, 11);

  }
    return input;
  }

  const handleKeyPres = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[a-zA-Z\s.]+$/.test(char)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    fetchData()
    }, []);


  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoding(true)
    validateField()
    if(editErrors.industry_preferences !== '' || selectedCheckboxes?.length ==0){
      setLoding(false);
      toast.error("The Industry_Preferences field is required")
      editErrors.industry_preferences = 'The Industry_Preferences field is required';
      return false;
    }
    if (!Object.values(editErrors).some((error) => error !== '')) {
      try {
         const { data } = await axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-profile-update/${id}`, {
          website: formData.website,
          name: formData.fullname,
          address: formData.address,
          address_cont: formData.address_contact,
          city: formData.city_town,
          country: selectedCountry.label,
          province: selectedState.label,
          postal_code: formData.postal_code,
          phone_code:phoneOption,
          phone: formData.phone,
          fax: formData.fax,
          company_description: formData.company_description,
          industry_preferences: selectedCheckboxes,
           status: formData.status,
          gender:formData?.gender
        },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
           })
        if (data.message === "User updated successfully") {
          toast.success(data?.message)
          setLoding(false);
        } else {
          toast.error(data?.message)
          setLoding(false);
        }
        
        cancel();
      } catch (error) {
        //  console.log('apiErrors', error);
          setLoding(false) }
    }
    else { setErrors(editErrors); setLoding(false) }
  }

  const fetchData = async (e) => {
    setSpinner(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-get-profile`,
        {
          params: { id: id, },
          headers: { Authorization: `Bearer ${token}`, }
        }
      );
      if (response.data) {
        const getUserData = response.data;
        setFormdata((prevData) => ({
          ...prevData,
          username: getUserData.username,
          organization: getUserData.organization,
          fullname: getUserData.name,
          emailaddress: getUserData.email,
          website: getUserData.website,
          address: getUserData.address,
          address_contact: getUserData.address_cont,
          city_town: getUserData.city,
          country: getUserData.country,
          province: getUserData.province,
          postal_code: getUserData.postal_code,
          phone_code:getUserData.phone_code,
          phone: getUserData.phone,
          fax: getUserData.fax,
          company_description: getUserData.company_description,
          status: getUserData.status,
          image: getUserData?.profile_picture,
          gender: getUserData?.gender
        }));
        setSelectedCountry({ value: getUserData.country, label: getUserData.country });
        setSelectedState({ value: getUserData.province, label: getUserData.province });
        setSelectedCheckboxes(getUserData.industry_preferences);
        setPhoneOption(getUserData?.phone_code)
      }
      setSpinner(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  


  const deleteUser = async () => {
     try {
       const confirmDeletion = await Swal.fire({
         title: "Delete User",
         text: "Do you want to delete this user? This action cannot be undone.",
         icon: "warning",
         showCancelButton: true,
         cancelButtonColor: "#D0D5DD",
         confirmButtonColor: "#D92D20",
         confirmButtonText: "Delete",
         iconHtml: '<i class="fas fa-trash-alt"></i>',
         iconColor: "red",
       });
       setSpinner(false);
       if (!confirmDeletion.isConfirmed) return;
       const response = await axios.delete(
         `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-delete/${id}`,
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         }
       );
       cancel();
       toast.success(response?.data?.message);
       setSpinner(false);
     } catch (error) {
       console.log(error);
     }
  };

  
  const cancel = () => { 
    navigate(-1)
  };

  return (
    <>
      {spinner ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="pt-4 mb-4">
          <div className="row py-4 ">
              <Col sm={12} className='px-0' >
                <span  onClick={cancel} style={{cursor: "pointer",color: "#0F66B4",fontWeight: "500",}} className="p-1 position-static">
                      <i className="fa-solid fa-arrow-left-long me-2"></i>Go Back
                </span>
              </Col>
            </div>
          <Row>
            <Col style={{ background: "#137CD9", color: "#fff" }}>
              <h2 className="my-2">PCMA Members Management</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12} style={{ background: "#fff" }}>
              <div className="d-flex justify-content-between p-3">
                <div className="d-flex">
                  <div>
                    {formData?.image}
                  {formData?.image !== (undefined || '') ? 
                                       <img
                                       src={formData?.image}
                                       alt="ceoimage"
                                       className="img-fluid"
                                       style={{
                                         height: "50px",
                                         width: "50px",
                                         borderRadius: "50%",
                                       }}
                                     />:
                                    <img
                      src="/asset/users.png"
                      alt="ceoimage"
                      className="img-fluid"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                                      }

                   
                  </div>
                  <div className="px-1">
                    <div>
                      {formData.fullname}{" "}
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 19H6.425L16.2 9.225L14.775 7.8L5 17.575V19ZM3 21V16.75L16.2 3.575C16.4 3.39167 16.621 3.25 16.863 3.15C17.105 3.05 17.359 3 17.625 3C17.8917 3 18.15 3.05 18.4 3.15C18.65 3.25 18.8667 3.4 19.05 3.6L20.425 5C20.625 5.18333 20.771 5.4 20.863 5.65C20.955 5.9 21.0007 6.15 21 6.4C21 6.66667 20.9543 6.921 20.863 7.163C20.7717 7.405 20.6257 7.62567 20.425 7.825L7.25 21H3ZM15.475 8.525L14.775 7.8L16.2 9.225L15.475 8.525Z"
                          fill="#7C7C7C"
                        />
                      </svg> */}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Form
                    className="form-check form-switch"
                    onSubmit={handleSubmit}
                  >
                    <input
                      className="form-check-input"
                      onChange={toggleStatus}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={formData.status}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      {formData.status ? "Enabled" : "Disabled"}
                    </label>
                  </Form>
                  <div className="px-2">
                    {" "}
                    <Link
                      onClick={deleteUser}
                      style={{ cursor: "pointer", color: "#000", textDecoration: "none"  }}
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 18 20"
                        fill="none"
                      >
                        <path
                          d="M3.33333 20C2.72222 20 2.19926 19.7826 1.76444 19.3478C1.32963 18.913 1.11185 18.3896 1.11111 17.7778V3.33333H0V1.11111H5.55556V0H12.2222V1.11111H17.7778V3.33333H16.6667V17.7778C16.6667 18.3889 16.4493 18.9122 16.0144 19.3478C15.5796 19.7833 15.0563 20.0007 14.4444 20H3.33333ZM14.4444 3.33333H3.33333V17.7778H14.4444V3.33333ZM5.55556 15.5556H7.77778V5.55556H5.55556V15.5556ZM10 15.5556H12.2222V5.55556H10V15.5556Z"
                          fill="#FE6464"
                        />
                      </svg>{" "}
                      Delete 
                    </Link>
                  </div>
                </div>
              </div>
              <div></div>
              <div></div>
            </Col>
              <Col xs={12} md={12} lg={12} style={{ background: "#fff" }}>
                <div className="p-3" style={{ background: "#137CD9", color: "#fff" }}>
                 General Information
                </div>
                <Form className="row" onSubmit={handleSubmit}>
                  <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Full Name
                      <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <input
                          className={`form-control
                          ${((editErrors.fullname) && (formData.fullname === '')) && 'error_active'}
                          ${((editErrors.fullname === '') && (formData.fullname)) && 'sucess_active'}`}
                        type="text"
                        name="fullname"
                        maxLength={30}
                        value={formData?.fullname || ''}
                        onChange={handleChange}
                        onKeyPress={handleKeyPres}
                        onPaste={handlePaste}
                      />
                      <span style={{ color: "red" }}>
                        {editErrors.fullname}
                      </span>
                    </div>
                  </div>
                </Col>
                  <Col xxl={6} md={6} sm={12}>
                    <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0" htmlFor="name">Gender</label>
                      <div className="  flex-wrap flex-sm-nowrap " >
                                  <div className="form-check form-check-inline ">
                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio1"   value="male" onChange={handleChange} checked={formData?.gender === "male"} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                 </div>
                                  <div className="form-check form-check-inline ">
                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio2"  value="female" onChange={handleChange} checked={formData?.gender === "female"}  />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gender" id="inlineRadio2"   value="others" onChange={handleChange} checked={formData?.gender === "others"} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Others</label>
                                  </div>
                              </div>
                    </div>
                 </Col> 
                </Form>
            </Col>
            <Col xs={12} md={12} lg={12} style={{ background: "#fff" }}>
              <div
                className="p-3"
                style={{ background: "#137CD9", color: "#fff" }}
              >
                Account Information
              </div>
              <Form className="row" onSubmit={handleSubmit}>
                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Username
                    <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>  </label>
                    <div className="w-100">
                      <input
                        className="form-control"
                        type="text"
                        name="username"
                        value={formData?.username || ''}
                        onChange={handleChange}
                        disabled={formData?.username}
                      />
                      <span style={{ color: "red" }}>
                        {editErrors.username}
                      </span>
                    </div>
                  </div>
                </Col>

                

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Email Address
                      <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <input
                        className="form-control"
                        type="email"
                        name="emailaddress"
                        value={formData?.emailaddress || ''}
                        onChange={handleChange}
                        disabled={formData.emailaddress}
                      />
                      <span style={{ color: "red" }}>
                        {editErrors.emailaddress}
                      </span>
                    </div>
                  </div>
                </Col>
              </Form>
            </Col>

            <Col xs={12} md={12} lg={12} style={{ background: "#fff" }}>
              <div
                className="p-3"
                style={{ background: "#137CD9", color: "#fff" }}
              >
                Professionals Information
              </div>
              <Form className="row" onSubmit={handleSubmit}>
                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Organization
                      <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <input
                        className="form-control"
                        type="text"
                        name="organization"
                        value={formData?.organization || ''}
                        onChange={handleChange}
                        disabled={formData?.organization}
                      />
                      <span style={{ color: "red" }}>
                        {editErrors.organization}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                    <label className="form_label mb-2 mb-sm-0"> Website </label>
                    <div className="w-100">
                      <input
                         className={`form-control
                         ${(((editErrors.website) && (formData.website ==='')) || (editErrors.website)) && 'error_active'}
                         ${((editErrors.website ==='') && (formData.website)) && 'sucess_active'}`}
                        type="url"
                        name="website"
                        value={formData?.website || ''}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {" "}
                        {editErrors.website}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Address
                        <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <input
                        className={`form-control
                        ${(((editErrors.address) && (formData.address === '')) || (editErrors.address)) && 'error_active'}
                        ${((editErrors.address === '') && (formData.address)) && 'sucess_active'}`}
                        type="text"
                        name="address"
                        value={formData?.address || ''}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {editErrors.address}{" "}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                    <label className="form_label mb-2 mb-sm-0">Address Cont. </label>
                    <div className="w-100">
                      <input
                        className={`form-control
                        ${((editErrors.address_contact === '') && (formData.address_contact)) && 'sucess_active'}`}
                        type="text"
                        name="address_contact"
                        value={formData?.address_contact || ''}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {" "}
                        {editErrors.address_contact}{" "}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> City / Town
                      <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <input
                       className={`form-control
                       ${(((editErrors.city_town) && (formData.city_town === '')) || (editErrors.city_town)) && 'error_active'}
                       ${((editErrors.city_town === '') && (formData.city_town)) && 'sucess_active'}`}
                        type="text"
                        name="city_town"
                        value={formData?.city_town || ''}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {editErrors.city_town}{" "}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0">Country
                    <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>  </label>
                    <div className="w-100">
                      <Select
                       className={`
                       ${(((editErrors?.country) && (formData?.country === '')) || (editErrors.country)) && 'error_active'}
                       ${((editErrors?.country === '') || (formData?.country)) && 'sucess_active'}`}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        options={countries}
                        placeholder="Search for a country"
                        isSearchable
                      />
                      <span style={{ color: "red" }}>
                        {" "}
                        {editErrors.country}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Province
                        <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <Select
                       className={`
                       ${(((editErrors.province) && (formData.province === '')) || (editErrors.province) || selectedState === null) && 'error_active'}
                       ${(((editErrors.province === '') && (formData.province) && selectedState!==null  )) && 'sucess_active'}`}
                        value={selectedState}
                        onChange={handleStateChange}
                        options={states}
                        placeholder="Search for a state"
                          isSearchable
                        isDisabled={isdisabled}
                      />
                      
                      <span style={{ color: "red" }}>
                        {" "}
                        {editErrors.province}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Postal Code
                      <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                    <div className="w-100">
                      <input
                        className={`form-control
                        ${(((editErrors.postal_code) && (formData.postal_code === '')) || (editErrors.postal_code)) && 'error_active'}
                        ${((editErrors.postal_code === '') && (formData.postal_code)) && 'sucess_active'}`}
                        type="text"
                        maxLength={10}
                        name="postal_code"
                        value={formData?.postal_code || ''}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {" "}
                        {editErrors.postal_code}
                      </span>
                    </div>
                  </div>
                  
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Phone
                      <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg></label>
                      <div className="w-100">
                        <div className='d-flex align-items-center'>
                          <div className="select_code">
                            <input
                                className="form-control"
                                id="phone_code"
                                type="text"
                                name="phone_code"
                                value={phoneCodeFormat(formData?.phone_code ||' ')}
                                onChange={handleChange}
                                disabled
                                />
                                {/* <Select
                                 className={`
                                 ${(((editErrors.phone_code) && (formData.phone_code === '')) || (editErrors.phone_code)) && 'error_active'}
                                 ${((editErrors.phone_code === '') && (formData.phone_code)) && 'sucess_active'}`}
                                  type="text"
                                  name="code"
                                  value={phoneCode.find((option) => option.value === phoneOption)}
                                  onChange={handlePhoneCode}
                                  options={phoneCode}
                                  isSearchable
                                  placeholder="Code"
                                /> */}
                          </div>
                        <input
                        className={`
                        form-control ms-1
                          ${(((editErrors.phone) && (formData.phone === '')) ||(editErrors.phone) ) && 'error_active'}
                          ${((editErrors.phone === '') && (formData.phone) ) && 'sucess_active'}
                        `}
                        type="text"
                        maxLength={14}
                        onKeyPress={handleKeyPress}
                        name="phone"
                        value={phoneFormat(formData?.phone || '')}
                        onChange={handleChange}
                      />
                        </div>
                        <span style={{ color: "red" }}>{editErrors.phone}</span>
                      </div>
                    
                  </div>
                 
                </Col>

                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex align-items-center  py-2">
                    <label className="form_label mb-2 mb-sm-0"> Fax </label>
                    <div className="w-100">
                      <input 
                       className={`
                       form-control
                         ${((editErrors.fax === '') && (formData.fax) ) && 'sucess_active'}
                       `}
                      maxLength={10} type="text" name="fax" onKeyPress={handleKeyPress} value={formData.fax || ''} onChange={handleChange} />
                      <span style={{ color: "red" }}> {editErrors.fax} </span>
                    </div>
                  </div>
                </Col>

                  <Col xxl={6} md={6} sm={12} className='d-flex justify-content-between flex-column pb-3'>
                    <div className="d-sm-flex align-items-center  py-2">
                      <label className="form_label mb-2 mb-sm-0"> Company Description
                        <svg
                          className="ms-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                        >
                          <path
                            d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                            fill="#F92828"
                          />
                        </svg>
                      </label>
                      <div className="w-100">
                        <input
                          className={`
                       form-control
                         ${((editErrors.company_description) && (formData.company_description === '') || (editErrors.company_description)) && 'error_active'}
                         ${((editErrors.company_description === '') && (formData.company_description)) && 'sucess_active'}
                       `}
                          type="text"
                           name="company_description"
                            value={formData.company_description || ''} 
                            onChange={handleChange} />
                        <span style={{ color: "red" }}>
                          {editErrors.company_description}
                        </span>
                      </div>
                    </div>
                  </Col>
                <Col xxl={6} md={6} sm={12}>
                  <div className="d-sm-flex  py-2">
                      <label className="form_label mb-2 mb-sm-0">Industry Preferences
                        <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg> </label>
                    <div className={`
                                input_checkbox form-control  p-2 rounded Preferences_check
                                  ${((editErrors.industry_preferences) && (selectedCheckboxes.length === 0)) && 'error_active'}
                                  ${((editErrors.industry_preferences === '') && (selectedCheckboxes.length>0)) && 'sucess_active'}
                                `}>
                      <div className="form-check mb-2">
                        <input
                          className={`form-check-input ${false ? 'is-invalid' : ''}`} id="flexCheckDefault1"  name="industry_preferences" type="checkbox" value="Select All / None"
                            // onChange={handleCheckboxChange} checked={selectedCheckboxes?.includes("Select All / None")}
                             onChange={handleCheckboxChangeAll} checked={selectedCheckboxes?.length >= 8}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault1">(Select All / None) </label>
                      </div>
                      {[
                        "Private Placements Mining",
                        "Private Placements Oil and Gas",
                        "Private Placements - Other",
                        "Portfolio Management with Exempt Clients",
                        "Mergers and Acquisitions",
                        "Private Placements - Real Estate",
                        "Distributor of Exempt Investments",
                        "Other"
                      ].map((label, index) => (
                        <div className="form-check mb-2" key={index}>
                          <input
                            className="form-check-input" type="checkbox" value={label}
                            
                            id={`flexCheckChecked${index + 2}`} onChange={handleCheckboxChange}
                            checked={selectedCheckboxes?.includes(label)}
                          />
                          <label className="form-check-label" htmlFor={`flexCheckChecked${index + 2}`}> {label} </label>
                        </div>
                      ))}
                    </div>
                   
                    </div>
                     <span style={{ color: "red" }}>{editErrors.industry_preferences}</span>
                </Col>
                <div className="d-flex align-items-center">
                    <button type='submit' className='me-2 user_form_submit' onClick={handleSubmit} disabled={loading}>Submit {loading ? (<i className="fa-solid fa-spinner fa-spin ms-2"></i>) : null} </button>
                  <button type='button' className='user_form_cancal' onClick={cancel}>Cancel</button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
export default AdminsersForm ;
